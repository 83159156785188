import {addParamsToUrl} from './api/helpers';
import commonClient from './commonClient';

const onFetch = async ({url, method = 'GET', headers = {}, signal}) => {
  try {
    const response = await commonClient({
      url: `${process.env.REACT_APP_FLEET_SERVER_URL}${url}`, //Full URL
      method,
      headers,
      signal,
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const onPost = async ({url, method = 'POST', data}) => {
  try {
    const response = await commonClient({
      url: `${process.env.REACT_APP_FLEET_SERVER_URL}${url}`, // Full URL
      method,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const onPostStagJarvis = async ({url, method = 'POST', data}) => {
  try {
    const response = await commonClient({
      url: `${process.env.REACT_APP_JARVIS_SERVER_URL}${url}`, // Full URL
      method,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const onPost1 = async ({url, method = 'POST', data}) => {
  try {
    const response = await commonClient({
      url: `${process.env.REACT_APP_FLEET_SERVER_URL}${url}`, // Full URL
      method,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const onPost1StagJarvis = async ({url, method = 'POST', data}) => {
  try {
    const response = await commonClient({
      url: `${process.env.REACT_APP_JARVIS_SERVER_URL}${url}`, // Full URL
      method,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const onPatch = async ({url, method = 'PATCH', data}) => {
  try {
    const response = await commonClient({
      url: `${process.env.REACT_APP_FLEET_SERVER_URL}${url}`, // Full URL
      method,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const onPatchStagJarvis = async ({url, method = 'PATCH', data}) => {
  try {
    const response = await commonClient({
      url: `${process.env.REACT_APP_JARVIS_SERVER_URL}${url}`, // Full URL
      method,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const onFetchStagJarvis = async ({url, method = 'GET', headers = {}}) => {
  try {
    const response = await commonClient({
      url: `${process.env.REACT_APP_JARVIS_SERVER_URL}${url}`,
      method,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const onPostProd = async ({url, method = 'POST', data}) => {
  try {
    const response = await commonClient({
      url: `${process.env.REACT_APP_JARVIS_SERVER_URL}${url}`, // Full URL
      method,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const onPostProd1 = async ({url, method = 'POST', data}) => {
  try {
    const response = await commonClient({
      url: `${process.env.REACT_APP_JARVIS_SERVER_URL}${url}`, // Full URL
      method,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const onPatchProd = async ({url, method = 'PATCH', data}) => {
  try {
    const response = await commonClient({
      url: `${process.env.REACT_APP_JARVIS_SERVER_URL}${url}`, // Full URL
      method,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const onPut = async ({url, method = 'PUT', data}) => {
  try {
    const response = await commonClient({
      url: `${process.env.REACT_APP_JARVIS_SERVER_URL}${url}`, // Full URL
      method,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const onDeleteJarvis = async ({url, method = 'DELETE', headers = {}}) => {
  try {
    const response = await commonClient({
      url: `${process.env.REACT_APP_JARVIS_SERVER_URL}${url}`,
      method,
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

const addOptionalQueryParams = (url, params) => {
  let urlTemp = url;
  if (params.search) {
    Object.entries(params.search).forEach(([key, value]) => {
      if (urlTemp.endsWith('?')) {
        urlTemp = `${urlTemp}${key}=${value}`;
      } else {
        urlTemp = `${urlTemp}&${key}=${value}`;
      }
    });
  }
  if (params.sort) {
    if (urlTemp.endsWith('?')) {
      urlTemp = `${urlTemp}sort_key=${params.sort.sort_key}&sort_order=${params.sort.sort_order}`;
    } else {
      urlTemp = `${urlTemp}&sort_key=${params.sort.sort_key}&sort_order=${params.sort.sort_order}`;
    }
  }
  return urlTemp;
};

const modifyUrlForFiltering = (url, filters, isCommitmentMapping = false) => {
  if (isCommitmentMapping) {
    // Logic specifically for onFetchCommitmentMappingApi
    let queryParams = [];

    if (filters?.search && Object.keys(filters.search).length > 0) {
      queryParams.push('search_key=1'); // Add search_key=1 only for searches
      Object.entries(filters.search).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
          queryParams.push(`${key}=${encodeURIComponent(value)}`);
        }
      });
    }

    if (filters?.sort) {
      const {sort_key, sort_order} = filters.sort;
      if (sort_key && sort_order) {
        queryParams.push(`sort_key=${encodeURIComponent(sort_order)}`);
        queryParams.push(`sort_column=${encodeURIComponent(sort_key)}`);
      }
    }

    if (filters?.fields) {
      queryParams.push(`fields=${encodeURIComponent(filters.fields)}`);
    }

    // Return modified URL with appended query parameters
    return queryParams.length > 0 ? `${url}&${queryParams.join('&')}` : url;
  } else {
    // General filtering logic for other pages
    let urlTemp;
    if (Object.keys(filters || {}).length) {
      if (filters.search) {
        Object.entries(filters.search).forEach(([key, value]) => {
          if (urlTemp) {
            urlTemp = `${urlTemp}&${key}=${value}`;
          } else {
            urlTemp = `${key}=${value}`;
          }
        });
      }
      if (filters.sort) {
        if (urlTemp) {
          urlTemp = `${urlTemp}&sort_key=${filters.sort.sort_key}&sort_order=${filters.sort.sort_order}`;
        } else {
          urlTemp = `sort_key=${filters.sort.sort_key}&sort_order=${filters.sort.sort_order}`;
        }
      }
      return `${url}&${urlTemp}`;
    }
    return url;
  }
};

const modifyUrlForSpecificFiltering = (url, filters) => {
  let urlTemp;
  if (Object.keys(filters || {}).length) {
    if (filters.search) {
      Object.entries(filters.search).forEach(([key, value]) => {
        if (urlTemp) {
          urlTemp = `${urlTemp}&search_column=${key}&search_pattern=${value}`;
        } else {
          urlTemp = `search_column=${key}&search_pattern=${value}`;
        }
      });
    }
    if (filters.sort) {
      if (urlTemp) {
        urlTemp = `${urlTemp}&sort_key=${filters.sort.sort_key}&sort_order=${filters.sort.sort_order}`;
      } else {
        urlTemp = `sort_key=${filters.sort.sort_key}&sort_order=${filters.sort.sort_order}`;
      }
    }
    return `${url}&${urlTemp}`;
  }
  return url;
};

export const onFetchDriverManagerDetails = ({user, dashboard}) =>
  onFetch({url: `/jarvis_api/api/manager_details/?user_name=${user}&dash=${dashboard}`});

export const onFetchCityWiseGarageDetails = () => onFetchStagJarvis({url: `/garage/city-wise`});

export const onFetchLOVs = (name, loc) => {
  let url = `/common_utils/lov?lov_names=${name}`;
  if (name === 'parking_complex') {
    url = `${url}&column=company_loc_id&value=${loc}`;
  } else if (name === 'roles') {
    url = `${url}&column=type&value=onboard`;
  }
  return onFetchStagJarvis({url, method: 'GET'});
};

export const onFetchOnboardStatus = name => {
  const url = `/common_utils/lov?lov_names=status_lookup&column=type&value=${name}`;
  return onFetchStagJarvis({url, method: 'GET'});
};

export const fetchDrivingTestFailReason = () => {
  const url = `/common_utils/lov?lov_names=everest_lookup&column=type&value=driving-test-fail-reason`;
  return onFetchStagJarvis({url, method: 'GET'});
};

export const onFetchLocationList = () => onFetchStagJarvis({url: '/common_utils/city-wise-location', method: 'GET'});

export const onFetchReasonForNotJoining = () =>
  onFetchStagJarvis({
    url: '/common_utils/lov?column=type&value=walkin-reason-for-not-joining&lov_names=everest_lookup',
    method: 'GET',
  });

export const onFetchReasonForVisit = () =>
  onFetchStagJarvis({
    url: '/common_utils/lov?column=type&value=walkin-purpose-of-visit&lov_names=everest_lookup',
    method: 'GET',
  });

export const onFetchTeamsList = (locId, searchValue) => {
  let url = `/fleet/api/teamApi/?loc_id=${locId}`;
  if (searchValue) {
    url = `${url}&team_name=${searchValue}`;
  }
  return onFetchStagJarvis({url, method: 'GET'});
};

export const onFetchCarDetails = carID => onFetchStagJarvis({url: `/car/car-details/${carID}`, method: 'GET'});

// Checkin module //

export const onFetchCarDetailApi = id => onFetchStagJarvis({url: `/car/car-details/${id}`});

export const onFetchStatusApi = id => onFetchStagJarvis({url: `/car/checkin-status?car_id=${id}`, method: 'GET'});

export const onFetchDropOffApi = id => onFetchStagJarvis({url: `/car/drop-off?car_id=${id}`, method: 'GET'});

export const onFetchCheckInDetailsApi = id => onFetchStagJarvis({url: `/car/checkin-details/${id}`});

export const onFetchParkingSectionsApi = (id, page, size, loc) =>
  onFetchStagJarvis({
    url: `/parking/section?loc_id=${loc}&sort_key=name&sort_order=ASC&page=${page}&page_size=${size}&parking_complex_id=${id}`,
    method: 'GET',
  });

export const onFetchParkingBaysApi = (id, page, size, loc, searchValue) => {
  let url = `/parking/bay?loc_id=${loc}&page=${page}&page_size=${size}&sort_key=display_order&sort_order=ASC&parking_section_id=${id}&status=Available`;
  if (searchValue) {
    url = `${url}&name=${searchValue}`;
  }
  return onFetchStagJarvis({url, method: 'GET'});
};

export const onFetchParkingDetailsApi = id => onFetchStagJarvis({url: `/parking?parking_id=${id}`});

export const onFetchCarTyreApi = id => onFetchStagJarvis({url: `/car/current-car-tyre-info/${id}`, method: 'GET'});

export const onFetchCarBatteryApi = id =>
  onFetchStagJarvis({url: `/car/current-car-battery-info/${id}`, method: 'GET'});

export const onPostAuditDetailsApi = fd => onPostProd1({url: '/car/audit', method: 'POST', data: fd});

export const onFetchAuditDetailsApi = id => onFetchStagJarvis({url: `/car/audit/${id}`});

export const onPostDeallocationDetailsApi = fd => onPostProd1({url: '/car/deallocation', method: 'POST', data: fd});

export const onFetchDeallocationDetailsApi = id => onFetchStagJarvis({url: `/car/deallocation/${id}`});

export const onFetchBatteryListApi = (loc, page, size, filters, checked, complexId) => {
  const params = {loc_id: loc, page: page, page_size: size, historical: checked, complex_id: complexId};
  const url = modifyUrlForFiltering(addParamsToUrl({baseUrl: '/car/battery', params}), filters);
  return onFetchStagJarvis({url});
};

export const onFetchTyreListApi = (loc, page, size, filters, checked, complexId) => {
  const params = {loc_id: loc, page: page, page_size: size, historical: checked, complex_id: complexId};
  const url = modifyUrlForFiltering(addParamsToUrl({baseUrl: '/car/tyre', params}), filters);

  return onFetchStagJarvis({url});
};
export const onFetchCarTyreInfoDetails = carID =>
  onFetchStagJarvis({url: `/car/current-car-tyre-info/${carID}`, method: 'GET'});
export const onPostBatteryDetailsApi = fd => onPostProd1({url: '/car/battery', method: 'POST', data: fd});
export const onPatchBatteryDetailsApi = (fd, id) => onPatchProd({url: `/car/battery/${id}`, method: 'PATCH', data: fd});
export const onPostTyreDetailsApi = fd => onPostProd1({url: '/car/tyre', method: 'POST', data: fd});
export const onPatchTyreDetailsApi = (carID, fd) => onPostProd1({url: `/car/tyre/${carID}`, method: 'PATCH', data: fd});

export const onPostBulkDataUpload = payload =>
  onPostStagJarvis({
    url: '/common_utils/file-upload',
    method: 'POST',
    data: payload,
  });
export const onFetchBulkDataDetails = (loc, page, size, filters, complexId) => {
  const params = {loc_id: loc, page: page, page_size: size, complex_id: complexId};
  const url = modifyUrlForFiltering(addParamsToUrl({baseUrl: '/common_utils/file-upload', params}), filters);
  return onFetchStagJarvis({url});
};
export const onFetchBulkDataErrorFile = id =>
  onFetchStagJarvis({
    url: `/common_utils/common-utils-file-upload-error/${id}`,
    method: 'GET',
  });

export const onFetchDocumentationChargeApi = (city, page, size, filters) => {
  const url = modifyUrlForFiltering(
    `/driver/documentation_charge_list?city_id=${city}&is_amount_paid=1&page=${page}&page_size=${size}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const onFetchDocumentationChargeDetailApi = id =>
  onFetchStagJarvis({url: `/driver/documentation_charge?id=${id}`, method: 'GET'});

export const onFetchDocumentationInvoiceApi = id =>
  onFetchStagJarvis({url: `/driver/driver_document_invoice?deposite_id=${id}`, method: 'GET'});

export const onPatchDocumentationChargeApi = (id, data) =>
  onPostProd1({
    url: `/driver/documentation_charge/${id}`,
    method: 'PATCH',
    data,
  });

// export const onFetchSecurityDepositApi = (city, page, size, filters) => {
//   const url = modifyUrlForFiltering(
//     `/driver/security-deposit-list?city_id=${city}&page=${page}&page_size=${size}`,
//     filters
//   );
//   return onFetchStagJarvis({url});
// };

export const onFetchSecurityDepositApi = ({city, page, size, search, sort}) => {
  const url = addOptionalQueryParams(`/driver/security-deposit-list?city_id=${city}&page=${page}&page_size=${size}`, {
    search,
    sort,
  });
  return onFetchStagJarvis({url});
};

export const onFetchSecurityTransactionsApi = (city, page, size, filters) => {
  const url = modifyUrlForFiltering(
    `/driver/security-deposit-transaction?city_id=${city}&page=${page}&page_size=${size}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const onPatchSecurityTransactionsApi = id =>
  onPostProd1({
    url: `/driver/security-deposit-transaction/approve/${id}?approve=true`,
    method: 'PATCH',
  });

export const onFetchSecurityAdjustmentsApi = (city, page, size, filters) => {
  const url = modifyUrlForFiltering(
    `/driver/security-deposit-adjustments?city_id=${city}&page=${page}&page_size=${size}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const onFetchSecurityDepositDetailApi = id =>
  onFetchStagJarvis({url: `/driver/security-deposit?id=${id}`, method: 'GET'});

export const onFetchSecurityDepositTransactionDetailApi = (page, size, id) =>
  onFetchStagJarvis({
    url: `/driver/security-deposit-transaction?page=${page}&page_size=${size}&dsd_id=${id}`,
    method: 'GET',
  });

export const onFetchSecurityDepositPaymentDetailApi = (city, page, size, id) =>
  onFetchStagJarvis({
    url: `/driver/security-deposit-payment-schedule?city_id=${city}&page=${page}&page_size=${size}&dsd_id=${id}`,
    method: 'GET',
  });

export const onFetchSecurityDepositAdjustmentsDetailsApi = (city, page, size, driverId) =>
  onFetchStagJarvis({
    url: `/driver/security-deposit-adjustments?city_id=${city}&page=${page}&page_size=${size}&driver_id=${driverId}`,
    method: 'GET',
  });

// /////////////////////////////////////////

export const onFetchParkingApi = (loc, page, size, filters, checked) => {
  const url = modifyUrlForFiltering(
    `/parking?loc_id=${loc}&page=${page}&page_size=${size}&historical=${checked}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const onPostParkingTransferApi = fd => onPostProd1({url: '/parking/transfer', method: 'POST', data: fd});

export const onFetchParkingBayApi = (loc, page, size, filters) => {
  const url = modifyUrlForFiltering(
    `/parking/bay?loc_id=${loc}&page=${page}&page_size=${size}&sort_key=display_order&sort_order=ASC`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const onPostParkingBayApi = fd => onPostProd1({url: '/parking/bay', method: 'POST', data: fd});

export const onPatchParkingBayApi = (fd, id) => onPatchProd({url: `/parking/bay/${id}`, method: 'PATCH', data: fd});

// /////////////////////////////////////////

export const onPostCarTransferApi = fd => onPostProd({url: '/car/car-transfer', method: 'POST', data: fd});

export const onPostCarTransferFormApi = fd => onPostProd({url: '/car/status-transfer', method: 'POST', data: fd});

export const onFetchCarsNumberApi = (city, carNumber, statusId) => {
  let url = `/jarvis_api/api/car/${city},${carNumber.toUpperCase()}/`;
  if (statusId) {
    url = `${url}?status_id=${statusId}`;
  }
  return onFetch({url});
};

export const onFetchDriversIdApi = (city = 1, driverId) => {
  let url;
  if (city) {
    url = `/jarvis_api/api/driver/${city},${driverId.toUpperCase()}/`;
  } else {
    url = `/jarvis_api/api/driver/${driverId.toUpperCase()}/`;
  }
  return onFetch({url, method: 'GET'});
};

export const onFetchDriversSearchApi = driver => {
  let url = `/jarvis_api/api/driver_dropdown_list?driver=${driver.toUpperCase()}`;
  return onFetch({url, method: 'GET'});
};

export const onFetchUsernameApi = username =>
  onFetchStagJarvis({
    url: `/common_utils/user?username=${username}`,
    method: 'GET',
  });

export const onFetchPenaltyReversalApi = ({search, basis, revenueType}) =>
  onFetch({
    url: `/jarvis_api/api/penalty_reversal/?search=${search}&basis=${basis}&revenue_type=${revenueType}`,
    method: 'GET',
  });
export const onPostReversePenaltyApi = postData =>
  onPost({url: '/jarvis_api/api/penalty_reversal/', method: 'POST', data: postData});
export const onFetchTempDataCSVApi = fd => onPost({url: '/jarvis_api/api/tempdata_csv/', method: 'POST', data: fd});
export const onPostCommentApi = fd => onPost({url: '/jarvis_api/api/comment/', method: 'POST', data: fd});
export const onPostAuditFormApi = fd => onPost1({url: '/driver/auditform/', method: 'POST', data: fd});
export const onFetchDeapproveIdApi = (id, fd) =>
  onPatch({
    url: `/jarvis_api/api/temp_driver_penalty_deapprove/${id}/`,
    method: 'PATCH',
    data: fd,
  });
export const onFetchAuditFormApi = () => onFetch({url: '/driver/auditform/1/', method: 'GET'});
export const onPostTeamTargetApi = fd => onPost1({url: '/jarvis_api/api/team_target/', method: 'POST', data: fd});
export const onFetchTeamTargetApi = ({teamIDValue}) =>
  onFetch({url: `/jarvis_api/api/team_target/${teamIDValue}/`, method: 'GET'});

export const onPostDriverPenaltyApi = postData =>
  onPost({url: '/jarvis_api/api/temp_driver_penalty/', method: 'POST', data: postData});

export const onFetchDriverPermissionApi = (managerId, basis) =>
  onFetch({
    url: `/jarvis_api/api/driver_penalty_permission/?user_id=${managerId}&module=${basis}`,
    method: 'GET',
  });
export const onFetchApprovePenaltyApi = ({startOfWeek, endOfWeek, loc, basis}) =>
  onFetch({
    url: `/jarvis_api/api/temp_driver_penalty_fetch/?week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&approval=approve&loc=${loc}&basis=${basis}`,
    method: 'GET',
  });
export const onFetchApprovedPenaltyApi = ({startOfWeek, endOfWeek, loc, basis}) =>
  onFetch({
    url: `/jarvis_api/api/temp_driver_penalty_fetch/?week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&approval=approved&loc=${loc}&basis=${basis}`,
    method: 'GET',
  });

export const onFetchViewAdjustmentApi = ({basis, startOfWeek, endOfWeek, loc}) =>
  onFetch({
    url: `/jarvis_api/api/view_adjustment/?basis=${basis}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&loc_id=${loc}`,
    method: 'GET',
  });
export const onFetchDriverDepositListApi = (driverId, revenue) =>
  onFetch({
    url: `/jarvis_api/api/driver_deposit_list/?driver_id=${driverId}&rev_type=${revenue}`,
    method: 'GET',
  });
export const onFetchDriverCarAllocationApi = (driverId, date) =>
  onFetch({
    url: `/jarvis_api/api/driver-car-allocation/?driver_id=${driverId}&for_date=${date}`,
    method: 'GET',
  });

export const onFetchCarAdjustmentApi = ({car, for_date, type}) =>
  onFetch({
    url: `/jarvis_api/api/car_adjustment/?car_id=${car}&for_date=${for_date}&adj_type=${type}`,
    method: 'GET',
  });

export const onUpdatePenaltyApi = (id, updatedData) =>
  onPatch({
    url: `/jarvis_api/api/temp_driver_penalty/${id}/`,
    method: 'PATCH',
    data: updatedData,
  });

export const onUpdateDriverPenaltyApproveApi = formData =>
  onPatch({
    url: `/jarvis_api/api/temp_driver_penalty_approve/`,
    method: 'POST',
    data: formData,
  });

export const onFetchCarStatusFormApi = carNumber => onFetch({url: `/driver/carstatus/${carNumber}/`, method: 'GET'});
export const onPatchCarStatusFormApi = (carNumber, fd) =>
  onPost1({url: `/driver/carstatus/${carNumber}/`, method: 'PATCH', data: fd});
export const onFetchSearchDriverApi = carNumber =>
  onFetch({
    url: `/jarvis_api/leasing/search/allocated-driver/?car_number=${carNumber}`,
    method: 'GET',
  });
export const onFetchSearchPartnerApi = etmNumber =>
  onFetch({
    url: `/jarvis_api/leasing/search/partner/?partner_id=${etmNumber}`,
    method: 'GET',
  });
export const onPostAllocationFormApi = fd => onPost1({url: '/driver/allocationform/', method: 'POST', data: fd});
export const onPostJamaFormApi = fd => onPost1({url: '/driver/jamaform/', method: 'POST', data: fd});
export const onFetchCarDetailsApi = (carNumber, driver) =>
  onFetch({
    url: `/jarvis_api/leasing/search/car-team/?car_number=${carNumber}&driver=${driver}%20Driver`,
    method: 'GET',
  });
export const onPutCommentApi = (carID, fd) =>
  onPost({url: `/jarvis_api/api/comment/${carID}/`, method: 'PATCH', data: fd});
export const onGetCommentApi = () => onFetch({url: '/jarvis_api/api/comment/', method: 'GET'});
export const onFetchUserTeamsDisplayApi = ({managerIDValue}) =>
  onFetch({
    url: `/jarvis_api/api/users_teams_display/?user_id=${managerIDValue}`,
    method: 'GET',
  });
export const onFetchPermissionsApi = () => onFetch({url: '/user-module/permissions/', method: 'GET'});
export const onMalamaalPostCommentApi = fd =>
  onPost({url: '/jarvis_api/api/malamaal_weekly_comments/', method: 'POST', data: fd});

export const onFetchRevShareHisaabSummary = (
  {teamIDValue, city, loc, payment_model, business_vertical},
  {startOfWeek, endOfWeek},
  basis
) =>
  onFetch({
    url: `/jarvis_api/api/hisaab/?team_id=${teamIDValue}&city_id=${city}&loc_id=${loc}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&basis=${basis}`,
    method: 'GET',
  });
export const onFetchRevShareHisaabSummaryTable = (
  teamIDValue,
  city,
  loc,
  business_vertical,
  payment_model,
  startOfWeek,
  endOfWeek,
  basis
) =>
  onFetch({
    url: `/jarvis_api/api/hisaab/?team_id=${teamIDValue}&city_id=${city}&loc_id=${loc}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&basis=${basis}`,
    method: 'GET',
  });

export const onFetchHisaabWeekStatusApi = ({enterValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/driver_os/${enterValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchHisaabHighOSApi = ({teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/highos/${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchHisaabAmountRecoveredApi = ({teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/recover_amt/${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchHisaabDriverDataApi = driverId =>
  onFetch({url: `/jarvis_api/api/drivers/${driverId}/`, method: 'GET'});
export const onFetchMasterHisaabApi = ({teamIDValue}, {startOfWeek, endOfWeek}, basis) =>
  onFetch({
    url: `/jarvis_api/api/hisaab_excel/?team_id=${teamIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&basis=${basis}`,
    method: 'GET',
  });

export const onFetchDriverHisaabApi = (driverId, {startOfWeek, endOfWeek}, basis) =>
  onFetch({
    url: `/jarvis_api/api/driver_hisaab/?etm=${driverId}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&basis=${basis}&type=${'revshare'}`,
    method: 'GET',
  });
export const onFetchHisaabDataApi = (driverId, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/weekly_hisaab/${driverId},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchDriverWeeklyDataApi = driverId =>
  onFetch({url: `/jarvis_api/api/driver_weekly_data/${driverId}/`, method: 'GET'});
export const onFetchDriverPenaltyListApi = (driverId, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/driver_penalty_list/${driverId},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });

export const onFetchCarSummaryApi = ({teamIDValue}, {startOfWeek, endOfWeek, signal}) =>
  onFetch({
    url: `/jarvis_api/api/car_summary/${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
    signal,
  });
export const onFetchIncentiveApi = ({startOfWeek, endOfWeek}, {cityIDValue}) =>
  onFetch({
    url: `/jarvis_api/api/incentive/${startOfWeek},${endOfWeek},${cityIDValue}/`,
    method: 'GET',
  });
export const onFetchIncentivesDataApi = ({teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/car_inctive/${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchMetricForCarsApi = ({teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/car_status_weekly/${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });

export const onFetchDashDataApi = (
  {teamIDValue, city, loc, payment_model, business_vertical},
  {startOfWeek, endOfWeek},
  signal
) =>
  onFetch({
    url: `/jarvis_api/api/dash_data/?team_id=${teamIDValue}&city_id=${city}&loc_id=${loc}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
    signal,
  });
export const onFetchCarStatusApi = ({teamIDValue}, {day}, signal) =>
  onFetch({
    url: `/jarvis_api/api/car_status/${teamIDValue},${day}/`,
    method: 'GET',
    signal,
  });
export const onFetchCarDailySidebarApi = (carType, {teamIDValue}, {day}) =>
  onFetch({
    url: `/jarvis_api/api/cardaily/${carType},${teamIDValue},${day},${day}/`,
    method: 'GET',
  });
export const onFetchCarWeeklyCardApi = (carType, {carNumberValue}, {teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/carweekly/${carType},${carNumberValue},${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchCarDailyTableApi = (carType, {teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/cardaily/${carType},${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });

export const onFetchDriverRatingApi = ({teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/driver_rating/${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchDriverWeeklyApi = (driverRating, {teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/driver_weekly/${driverRating},${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchDriverDailyApi = (driverId, driverRating, {teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/driver_daily/${driverRating},${teamIDValue},${startOfWeek},${endOfWeek},${driverId}/`,
    method: 'GET',
  });

export const onFetchWeeklyReportApi = (selectedTeam, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/weekly_report/${selectedTeam},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchWeeklySummaryReportApi = (
  {teamIDValue, cityIDValue, locIDValue, businessVertical, paymentModel},
  {startOfWeek, endOfWeek}
) =>
  onFetch({
    url: `/jarvis_api/api/weekly_summary_report/?team_id=${teamIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&city_id=${cityIDValue}&loc_id=${locIDValue}&payment_model_id=${paymentModel}&business_vertical_id=${businessVertical}`,
    method: 'GET',
  });
export const onFetchTargetDashboardReportApi = (
  {teamIDValue, cityIDValue, locIDValue, business_vertical, payment_model},
  {startOfWeek, endOfWeek}
) =>
  onFetch({
    url: `/jarvis_api/api/target_dashboard/?team_id=${teamIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&city_id=${cityIDValue}&loc_id=${locIDValue}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}`,
    method: 'GET',
  });
export const onFetchDailyDashApi = ({teamIDValue, revenueType, locIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/${revenueType}/dash_daily_report/?team_id=${teamIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&loc_id=${locIDValue}`,
    method: 'GET',
  });
export const onFetchWeeklyReportUberKmApi = (selectedTeam, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/weekly_report/uberkm/${selectedTeam},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchWeeklyReportCDRateApi = (selectedTeam, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/weekly_report/cd_rate/${selectedTeam},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchWeeklyCompareApi = (selectedTeam, {startOfWeek, endOfWeek}, signal) =>
  onFetch(
    {
      url: `/jarvis_api/api/weekly_campare/${selectedTeam},${startOfWeek},${endOfWeek}/`,
      method: 'GET',
    },
    signal
  );
export const onFetchTeamStatsMultiApi = (
  {teamIDValue, cityIDValue, locIDValue, business_vertical, payment_model},
  {startOfWeek, endOfWeek},
  signal
) =>
  onFetch(
    {
      url: `/jarvis_api/api/team_stats/?team_id=${teamIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&city_id=${cityIDValue}&loc_id=${locIDValue}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}`,
      method: 'GET',
    },
    signal
  );
export const onFetchTripQualificationMultiApi = (
  {teamIDValue, cityIDValue, locIDValue, business_vertical, payment_model},
  {startOfWeek, endOfWeek},
  basis
) =>
  onFetch({
    url: `/jarvis_api/api/incentives_summary/?team_id=${teamIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&city_id=${cityIDValue}&loc_id=${locIDValue}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}&basis=${basis}`,
    method: 'GET',
  });
export const onFetchTeamStatsApi = (
  {teamIDValue, cityIDValue, locIDValue, businessVertical, paymentModel},
  {startOfWeek, endOfWeek},
  signal
) =>
  onFetch(
    {
      url: `/jarvis_api/api/team_stats/?team_id=${teamIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&city_id=${cityIDValue}&loc_id=${locIDValue}&payment_model_id=${paymentModel}&business_vertical_id=${businessVertical}`,
      method: 'GET',
    },
    signal
  );
export const onFetchWeeklyCompareReportApi = (
  {teamIDValue, cityIDValue, locIDValue, businessVertical, paymentModel},
  {startOfWeek, endOfWeek},
  signal
) =>
  onFetch(
    {
      url: `/jarvis_api/api/weekly_compare_report/?team_id=${teamIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&city_id=${cityIDValue}&loc_id=${locIDValue}&payment_model_id=${paymentModel}&business_vertical_id=${businessVertical}`,
      method: 'GET',
    },
    signal
  );
export const onFetchWeeklyCompareCDRateApi = ({teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/weekly_campare/cd_rate/${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchWeeklyCompareUberKmApi = (selectedTeam, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/weekly_campare/uberkm/${selectedTeam},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });

export const onFetchWeeklyCompareIncentiveApi = (
  {teamIDValue, cityIDValue, locIDValue, businessVertical, paymentModel},
  {startOfWeek, endOfWeek}
) =>
  onFetch({
    url: `/jarvis_api/api/incentive_summary/${teamIDValue},${startOfWeek},${endOfWeek},${cityIDValue},${locIDValue},${businessVertical},${paymentModel}/`,
    method: 'GET',
  });
export const onFetchCDRateLevelApi = ({teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/cd_rate_levels/${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });

export const onFetchDeadKMReportApi = (
  {teamIDValue, day, startOfWeek, basis, city, loc, business_vertical, payment_model},
  signal
) =>
  onFetch(
    {
      url: `/jarvis_api/api/dead_kms/?team_id=${teamIDValue}&city_id=${city}&loc_id=${loc}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}&&date=${day}&week_start_date=${startOfWeek}&basis=${basis}`,
      method: 'GET',
    },
    signal
  );
export const onFetchDeadKMWeekReportApi = ({teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/dead_km_weekly/${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchDeadKMSummaryApi = (
  {teamIDValue, cityIDValue, locIDValue, businessVertical, paymentModel},
  {startOfWeek, endOfWeek}
) =>
  onFetch({
    url: `/jarvis_api/api/dead_kms_summary/?team_id=${teamIDValue}&city_id=${cityIDValue}&loc_id=${locIDValue}&payment_model_id=${paymentModel}&business_vertical_id=${businessVertical}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
  });
export const onFetchDriverRatingReportApi = ({teamIDValue}) =>
  onFetch({url: `/jarvis_api/api/driver_report/${teamIDValue}/`, method: 'GET'});

export const onFetchEmpSummaryApi = employeeId =>
  onFetch({url: `/jarvis_api/api/etm_summary/${employeeId}/`, method: 'GET'});
export const onFetchEmpMonthlySummaryApi = employeeId =>
  onFetch({url: `/jarvis_api/api/monthly_report/${employeeId}/`, method: 'GET'});
export const onFetchRunningCarsApi = employeeId =>
  onFetch({url: `/jarvis_api/api/etm_cars/${employeeId}/`, method: 'GET'});
export const onFetchPenaltiesApi = employeeId =>
  onFetch({url: `/jarvis_api/api/driver_p/${employeeId}/`, method: 'GET'});
export const onFetchRtoFinesApi = employeeId =>
  onFetch({url: `/jarvis_api/api/driver_rto/${employeeId}/`, method: 'GET'});
export const onFetchDeadKmApi = employeeId =>
  onFetch({url: `/jarvis_api/api/daily_deadkm/${employeeId}/`, method: 'GET'});

export const onFetchCarParkingApi = (
  {teamIDValue, cityIDValue, locIDValue, businessVertical, paymentModel},
  {startOfWeek, endOfWeek},
  signal
) =>
  onFetch({
    url: `/jarvis_api/api/car_parking_report/?team_id=${teamIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&city_id=${cityIDValue}&loc_id=${locIDValue}&payment_model_id=${paymentModel}&business_vertical_id=${businessVertical}`,
    method: 'GET',
    signal,
  });
export const onFetchAllocationSum = ({teamIDValue}, {day}, signal) =>
  onFetch({
    url: `/jarvis_api/api/allocation_summary/${teamIDValue},${day}/`,
    method: 'GET',
    signal,
  });
export const onFetchLeasingAllocationSum = ({teamIDValue}, {day}, signal) =>
  onFetch({
    url: `/jarvis_api/leasing/allocation_summary/${teamIDValue},${day}/`,
    method: 'GET',
    signal,
  });
export const onFetchNDCountReportApi = (
  {teamIDValue, cityIDValue, locIDValue, businessVertical, paymentModel},
  {startOfWeek, endOfWeek},
  basis
) =>
  onFetch({
    url: `/jarvis_api/api/nd_count_report/?team_id=${teamIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&basis=${basis}&city_id=${cityIDValue}&loc_id=${locIDValue}&payment_model_id=${paymentModel}&business_vertical_id=${businessVertical}`,
    method: 'GET',
  });
export const onFetchNDCountApi = ({teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/not_driven_count_summary/${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchNDPerDayCountApi = ({teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/not_driven_perday_count/${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchIncentiveQwsApi = (
  {teamIDValue, cityIDValue, locIDValue, business_vertical, payment_model},
  {startOfWeek, endOfWeek},
  basis
) =>
  onFetch({
    url: `/jarvis_api/api/incentives_summary/?team_id=${teamIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&city_id=${cityIDValue}&loc_id=${locIDValue}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}&basis=${basis}`,
    method: 'GET',
  });

export const onFetchIncentiveQws2Api = (
  {teamIDValue, cityIDValue, locIDValue, business_vertical, payment_model},
  {startOfWeek, endOfWeek}
) =>
  onFetch({
    url: `/jarvis_api/api/incentive_qualification_slabs/?team_id=${teamIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&city_id=${cityIDValue}&loc_id=${locIDValue}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}`,
    method: 'GET',
  });
export const onFetchTripQualificationApi = (
  {teamIDValue, cityIDValue, locIDValue, businessVertical, paymentModel},
  {startOfWeek, endOfWeek},
  basis
) =>
  onFetch({
    url: `/jarvis_api/api/incentives_summary/?team_id=${teamIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&city_id=${cityIDValue}&loc_id=${locIDValue}&payment_model_id=${paymentModel}&business_vertical_id=${businessVertical}&basis=${basis}`,
    method: 'GET',
  });

export const onFetchIncentiveQws3Api = ({teamIDValue}, {startOfWeek, endOfWeek}, {cityIDValue}) =>
  onFetch({
    url: `/jarvis_api/api/incentive_qualification_detailed/${teamIDValue},${startOfWeek},${endOfWeek},${cityIDValue}/`,
    method: 'GET',
  });
export const onFetchManagementLiveReport = ({day}) =>
  onFetch({url: `/jarvis_api/management/live_report/?date=${day}/`, method: 'GET'});
export const onFetchTwoHourlyApi = ({day}, {locIDValue}) =>
  onFetch({url: `/jarvis_api/api/two_hourly/?loc_id=${locIDValue}&date=${day}`, method: 'GET'});
export const onFetchCarRecoveryApi = ({teamIDValue}, endOfWeek) =>
  onFetch({url: `/jarvis_api/api/car_recovery_weekly/${teamIDValue},${endOfWeek}/`, method: 'GET'});
export const onFetchCarRecoveryReportApi = ({teamIDValue, city, loc, payment_model, business_vertical}, {day}) =>
  onFetch({
    url: `/jarvis_api/api/car_recovery_report/?team_id=${teamIDValue}&date=${day}&city_id=${city}&loc_id=${loc}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}`,
    method: 'GET',
  });
export const onFetchDriverRatingSummaryApi = ({teamIDValue}, {day}) =>
  onFetch({url: `/jarvis_api/api/driver_rating_summary/${teamIDValue},${day}/`, method: 'GET'});
// export const onFetchMalamaalReportApi = ({ teamIDValue }) => onFetch({ url: `/api/malamaal_weekly/${teamIDValue}/`, method: 'GET' });
export const onFetchRecoveryDashApi = (basis, {cityIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/recovery/recovery_dash/?basis=${basis}&city_id=${cityIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
  });
export const onFetchSummaryDashApi = (basis, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/recovery/summary_dash/?basis=${basis}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
  });
export const onFetchDriverDailyReportApi = (
  {startOfWeek, endOfWeek},
  {teamIDValue},
  {day1, day2, day3, day4, day5, day6, day7}
) =>
  onFetch({
    url: `/fleet/driver_report?start_date=${startOfWeek}+&end_date=${endOfWeek}+&team_id=${teamIDValue}&dates%5B%5D=${day1}+&dates%5B%5D=${day2}+&dates%5B%5D=${day3}+&dates%5B%5D=${day4}+&dates%5B%5D=${day5}+&dates%5B%5D=${day6}+&dates%5B%5D=${day7}+`,
    method: 'GET',
  });

export const onFetchCarDailyReportApi = (
  {startOfWeek, endOfWeek},
  {teamIDValue},
  {day1, day2, day3, day4, day5, day6, day7}
) =>
  onFetch({
    url: `/fleet/car_report?start_date=${startOfWeek}+&end_date=${endOfWeek}+&team_id=${teamIDValue}&dates%5B%5D=${day1}+&dates%5B%5D=${day2}+&dates%5B%5D=${day3}+&dates%5B%5D=${day4}+&dates%5B%5D=${day5}+&dates%5B%5D=${day6}+&dates%5B%5D=${day7}+`,
    method: 'GET',
  });
export const onFetchCarDriverDailyReportApi = ({teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/car_driver_daily?start_date=${startOfWeek}&end_date=${endOfWeek}&team_id=${teamIDValue}`,
    method: 'GET',
  });

export const onFetchCommitmentMappingNewApi = (
  {teamIDValue, city, loc, payment_model, business_vertical},
  {startOfWeek, endOfWeek},
  page,
  size,
  filters
) => {
  const url = modifyUrlForFiltering(
    `/jarvis_api/api/cm_new/?team_id=${teamIDValue}&city_id=${city}&loc_id=${loc}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&page_size=${size}&page_number=${page}`,
    filters,
    true // Pass a flag to indicate this is for onFetchCommitmentMappingApi
  );
  return onFetch({url});
};

export const onFetchCommitmentMappingApi = (
  {teamIDValue, city, loc, payment_model, business_vertical},
  {startOfWeek, endOfWeek}
) =>
  onFetch({
    url: `/jarvis_api/api/commitment_mapping/?team_id=${teamIDValue}&city_id=${city}&loc_id=${loc}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
  });
export const onFetchCarReportExcelApi = ({startOfWeek, endOfWeek}, {teamIDValue}) =>
  onFetch({
    url: `/jarvis_api/api/car_report_csv?start_date=${startOfWeek}&end_date=${endOfWeek}&team_id=${teamIDValue}`,
    method: 'GET',
  });

export const onFetchHistoricalDataApi = searchQuery =>
  onFetch({url: `/jarvis_api/api/uber_daily_data/${searchQuery}/`, method: 'GET'});
export const onFetchTripLevelActivityApi = searchQuery =>
  onFetch({url: `/jarvis_api/api/uber_rawtrip_data/${searchQuery}/`, method: 'GET'});

export const onFetchWeeklyDataReportApi = ({
  teamIDValue,
  startOfWeek,
  endOfWeek,
  city,
  loc,
  payment_model,
  business_vertical,
}) =>
  onFetch({
    url: `/jarvis_api/api/weekly_report_data/?team_id=${teamIDValue}&city_id=${city}&loc_id=${loc}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
  });
export const onFetchDailyDataReportApi = ({
  teamIDValue,
  startDate,
  endDate,
  city,
  loc,
  payment_model,
  business_vertical,
}) =>
  onFetch({
    url: `/jarvis_api/api/daily_report/?team_id=${teamIDValue}&city_id=${city}&loc_id=${loc}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}&date=${endDate}&week_start_date=${startDate}`,
    method: 'GET',
  });
export const onFetchLiveDataOnlineReportApi = ({teamIDValue, city, loc, payment_model, business_vertical, date}) =>
  onFetch({
    url: `/jarvis_api/api/live_report/?team_id=${teamIDValue}&city_id=${city}&loc_id=${loc}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}&date=${date}`,
    method: 'GET',
  });
export const onFetchLiveDataOfflineReportApi = ({teamID, startDate, endDate}) =>
  onFetch({url: `/jarvis_api/api/live_data_offline/${teamID},${endDate},${startDate}/`, method: 'GET'});
export const onFetchHistoricalAllocationApi = ({teamIDValue, city, loc, payment_model, business_vertical}, basis) =>
  onFetch({
    url: `/jarvis_api/api/car_team_allocation/?team_id=${teamIDValue}&basis=${basis}&city_id=${city}&loc_id=${loc}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}`,
    method: 'GET',
  });
export const onFetchQualificationPendingApi = (
  {teamIDValue, city, loc, payment_model, business_vertical},
  {startOfWeek, endOfWeek}
) =>
  onFetch({
    url: `/jarvis_api/api/qualification_pending/?team_id=${teamIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&city_id=${city}&loc_id=${loc}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}`,
    method: 'GET',
  });
export const onFetchLeasingQualificationPendingApi = ({teamID, startOfWeek, endOfWeek, city}) =>
  onFetch({
    url: `/jarvis_api/leasing/incentive_unqualified_data/${teamID},${startOfWeek},${endOfWeek},${city}/`,
    method: 'GET',
  });

export const onFetchHelpCenterPendingApi = (city, basis) =>
  onPostProd({
    url: `/driver/escalations_list?city_id=${city}&basis=${basis}`,
    method: 'GET',
  });

export const onFetchHelpCenterClosedApi = ({startOfWeek, endOfWeek}, city, basis) =>
  onPostProd({
    url: `/driver/escalations_list?city_id=${city}&basis=${basis}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
  });
export const onFetchHelpCenterApi = (city, page, size, filters, basis, is_repeated) => {
  const url = modifyUrlForFiltering(
    `/driver/escalations_list?city_id=${city}&basis=${basis}&page_number=${page}&page_size=${size}${is_repeated}`,
    filters
  );
  return onFetchStagJarvis({url});
};
export const onFetchHelpCenterExcelApi = (city, basis) => {
  const url = modifyUrlForFiltering(
    `/driver/escalations_list?city_id=${city}&basis=${basis}&page_number=${1}&page_size=${10}&download=${'download'}`
  );
  return onFetchStagJarvis({url});
};
export const onFetchEscalationsConfigApi = () => onPostProd({url: '/dropdown/escalations_config', method: 'GET'});
export const onFetchDispositionsListApi = () => onPostProd({url: '/driver/dispositions_list', method: 'GET'});
export const onFetchDepartmentsListApi = () => onPostProd({url: '/driver/helpcentre-departments', method: 'GET'});
export const onFetchAddEscalationsApi = fd => onPostProd({url: '/driver/escalations', method: 'POST', data: fd});
export const onFetchEditEscalationsApi = (id, fd) =>
  onPostProd({url: `/driver/escalations/${id}`, method: 'PATCH', data: fd});

export const onFetchHelpcenterDashboardApi = cityId =>
  onFetch({
    url: `/jarvis_api/api/help_center_dashboard/?city_id=${cityId}`,
    method: 'GET',
  });
// Leasing Dashboard API's
export const onFetchLeasingIncentiveQwsApi = ({teamIDValue}, {startOfWeek, endOfWeek}, {cityIDValue}) =>
  onFetch({
    url: `/jarvis_api/leasing/incentive_qualification_weekly_summary/${teamIDValue},${startOfWeek},${endOfWeek},${cityIDValue}/`,
    method: 'GET',
  });
export const onFetchLeasingIncentiveQws2Api = ({teamIDValue}, {startOfWeek, endOfWeek}, {cityIDValue}) =>
  onFetch({
    url: `/jarvis_api/leasing/incentive_qualification_summary/${teamIDValue},${startOfWeek},${endOfWeek},${cityIDValue}/`,
    method: 'GET',
  });
export const onFetchLeasingIncentiveQws3Api = (
  {teamIDValue, cityIDValue, locIDValue, business_vertical, payment_model},
  {startOfWeek, endOfWeek}
) =>
  onFetch({
    url: `/jarvis_api/api/incentive_qualification_detailed/?team_id=${teamIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&city_id=${cityIDValue}&loc_id=${locIDValue}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}`,
    method: 'GET',
  });

export const onFetchLeasingHisaabSummary = (
  {teamIDValue, city, loc, payment_model, business_vertical},
  {startOfWeek, endOfWeek},
  basis
) =>
  onFetch({
    url: `/jarvis_api/leasing/hisaab/?team_id=${teamIDValue}&city_id=${city}&loc_id=${loc}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&basis=${basis}`,
    method: 'GET',
  });

export const onFetchOSReport = (
  {teamIDValue, revenueType, cityIDValue, locIDValue, payment_model, business_vertical},
  {startOfWeek, endOfWeek},
  basis
) =>
  onFetch({
    url: `/jarvis_api/${revenueType}/hisaab/?team_id=${teamIDValue}&city_id=${cityIDValue}&loc_id=${locIDValue}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&basis=${basis}`,
    method: 'GET',
  });

export const onFetchLeasingHisaabSummaryTable = (
  teamIDValue,
  city,
  loc,
  business_vertical,
  payment_model,
  startOfWeek,
  endOfWeek,
  basis
) =>
  onFetch({
    url: `/jarvis_api/leasing/hisaab/?team_id=${teamIDValue}&city_id=${city}&loc_id=${loc}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&basis=${basis}`,
    method: 'GET',
  });
export const onFetchLeasingDashData = (
  {teamIDValue, city, loc, payment_model, business_vertical},
  {startOfWeek, endOfWeek},
  signal
) =>
  onFetch({
    url: `/jarvis_api/leasing/dash_data/?team_id=${teamIDValue}&city_id=${city}&loc_id=${loc}&payment_model_id=${payment_model}&business_vertical_id=${business_vertical}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
    signal,
  });
export const onFetchLeasingHisaabDriverOSApi = ({teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/leasing/hisaab_driveros/${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchLeasingHisaabHighOSApi = ({teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/leasing/highos/${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchLeasingHisaabAmountRecoveredApi = ({teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/leasing/recover_amt/${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchLeasingMasterHisaabApi = ({startOfWeek, endOfWeek}, {locIDValue}) =>
  onFetch({
    url: `/jarvis_api/leasing/master_hisaab/${startOfWeek},${endOfWeek},${locIDValue}/`,
    method: 'GET',
  });
export const onFetchLeasingHisaabDetailedApi = ({teamIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/leasing/hisaab_detailed/${teamIDValue},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchLeasingSmsConsolidatedExcelApi = ({locIDValue}, {startOfWeek, endOfWeek}, basis) =>
  onFetch({
    url: `/jarvis_api/leasing/hisaab_excel/?location=${locIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&basis=${basis}`,
    method: 'GET',
  });
export const onFetchLeasingHisaabExcelApi = ({locIDValue}, {startOfWeek, endOfWeek}, basis, city) =>
  onFetch({
    url: `/jarvis_api/leasing/hisaab_excel/?location=${locIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&basis=${basis}${city}`,
    method: 'GET',
  });
export const onFetchLeasingCarStatusReportApi = ({teamIDValue, city, loc, paymentModelId, businessVerticalId}, {day}) =>
  onFetch({
    url: `/jarvis_api/api/car_status_report/?team_id=${teamIDValue}&city_id=${city}&loc_id=${loc}&payment_model_id=${paymentModelId}&business_vertical_id=${businessVerticalId}&date=${day}`,
    method: 'GET',
  });
export const onFetchLeasingCarStatusErrorApi = ({teamIDValue}, {day}) =>
  onFetch({
    url: `/jarvis_api/leasing/car_new_status_report/?team_id=${teamIDValue}&date=${day}`,
    method: 'GET',
  });
export const onFetchLeasingCarModelReportApi = ({teamIDValue}) =>
  onFetch({
    url: `/jarvis_api/leasing/car_model_report/?team_id=${teamIDValue}`,
    method: 'GET',
  });
export const onFetchLeasingCarAllocationReportApi = ({locIDValue}, basis) =>
  onFetch({
    url: `/jarvis_api/api/car_allocation_report/?loc_id=${locIDValue}&basis=${basis}`,
    method: 'GET',
  });

export const onFetchIncentivePlanHistoricalApi = ({locIDValue}) =>
  onFetch({
    url: `/jarvis_api/api/incentive_plan/?is_historical=1&loc_id=${locIDValue}`,
    method: 'GET',
  });
export const onFetchIncentivePlanCurrentApi = ({locIDValue}) =>
  onFetch({
    url: `/jarvis_api/api/incentive_plan/?is_historical=0&loc_id=${locIDValue}`,
    method: 'GET',
  });

export const onFetchLeasingDriverHisaabApi = (driverId, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/leasing/driver_hisaab/${driverId},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchDriverHisaabSummaryApi = driverId =>
  onFetch({url: `/jarvis_api/leasing/driver_hisaab_summary/${driverId}/`, method: 'GET'});
export const onFetchLeasDriverHisaabApi = (driverId, {startOfWeek, endOfWeek}, basis) =>
  onFetch({
    url: `/jarvis_api/api/driver_hisaab/?etm=${driverId}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&basis=${basis}&type=${'leasing'}`,
    method: 'GET',
  });
export const onFetchDriverProfileApi = (basis, driver) =>
  onFetch({
    url: `/jarvis_api/api/driver_profile/?basis=${basis}&driver_id=${driver}`,
    method: 'GET',
  });
export const onFetchDriverAdjustmentApi = (driverId, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/leasing/driver_adjustment/${driverId},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchDriverHisaabAllotmentApi = (driverId, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/api/partner_performance/?etm=${driverId}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
  });
export const onFetchDriverHisaabOlaTransApi = (driverId, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/leasing/driver_hisaab_ola_transactions/${driverId},${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchDriverRecoveryApi = driverId =>
  onFetch({url: `/jarvis_api/leasing/driver_recovery/${driverId}/`, method: 'GET'});
export const onFetchReconciliationReportApi = ({startOfWeek, endOfWeek}, locId, paymentModelId) =>
  onFetch({
    url: `/jarvis_api/api/reconciliation_report/?week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&loc_id=${locId}&payment_model_id=${paymentModelId}`,
    method: 'GET',
  });

// Management Dashboard API's
export const onFetchLocationNamesApi = () => onFetchStagJarvis({url: `/dropdown/company-locations`, method: 'GET'});
export const onFetchManagementCarSummaryApi = day =>
  onFetch({url: `/jarvis_api/management/dash_data/?date=${day}`, method: 'GET'});
export const onFetchManagementCarTripsApi = day =>
  onFetch({url: `/jarvis_api/management/cartrips_summary/${day}/`, method: 'GET'});
export const onFetchLocationCityNameApi = () =>
  onFetch({
    url: `/jarvis_api/management/location_names/`,
    method: 'GET',
  });
export const onFetchManagementCarLocationsApi = day =>
  onFetch({url: `/jarvis_api/management/carloc_summary/${day}/`, method: 'GET'});
export const onFetchRevSharePerformanceApi = ({startOfWeek, endOfWeek, locationId}) =>
  onFetch({
    url: `/jarvis_api/management/revshare_performance/?week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&loc_id=${locationId}`,
    method: 'GET',
  });
export const onFetchRevShareStatsApi = ({location, startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/management/rev_share_report/?loc_id=${location}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
  });
export const onFetchEipReportApi = ({startOfWeek, endOfWeek}, locId) =>
  onFetch({
    url: `/jarvis_api/management/eip_report/?week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&loc_id=${locId}`,
    method: 'GET',
  });
export const onFetchUtilReportApi = (date, locId) =>
  onFetch({
    url: `/jarvis_api/api/util_report/?date=${date}&loc_id=${locId}`,
    method: 'GET',
  });
export const onFetchRevShareStatsDownloadApi = ({startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/management/rev_share_stats_download_data/${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchWeeklyVehicleReportApi = (basis, {startDate, endDate, carNumber}) =>
  onFetch({
    url: `/jarvis_api/management/weekly_vehicle_report/?basis=${basis}&start_date=${startDate}&end_date=${endDate}&car_number=${carNumber}`,
    method: 'GET',
  });
export const onFetchWeeklyVehicleReportExcelApi = (basis, {startDate, endDate, locationId}) =>
  onFetch({
    url: `/jarvis_api/management/weekly_vehicle_report/?basis=${basis}&start_date=${startDate}&end_date=${endDate}&location=${locationId}`,
    method: 'GET',
  });
export const onFetchTotalCarReportApi = currentDate =>
  onFetch({url: `/jarvis_api/management/car_count_report/${currentDate}/`, method: 'GET'});
export const onFetchCarPurchaseReportApi = currentDate =>
  onFetch({
    url: `/jarvis_api/management/car_purchase_report/${currentDate}/`,
    method: 'GET',
  });
export const onFetchGPSLocationWiseReportApi = ({startDate, endDate}) =>
  onFetch({
    url: `/jarvis_api/management/gps_citywise_report/${startDate},${endDate}/`,
    method: 'GET',
  });
export const onFetchGpsCarWiseReportApi = ({startDate, endDate, locationId}) =>
  onFetch({
    url: `/jarvis_api/management/gps_carwise_report/${startDate},${endDate},${locationId}/`,
    method: 'GET',
  });
export const onFetchCarHistoryReportApi = (basis, {carNumber}) =>
  onFetch({
    url: `/jarvis_api/management/car_performance_report/?basis=${basis}&car_number=${carNumber}`,
    method: 'GET',
  });
export const onFetchCarHistoryExcelReportApi = (basis, {locationId}) =>
  onFetch({
    url: `/jarvis_api/management/car_performance_report/?basis=${basis}&location=${locationId}`,
    method: 'GET',
  });
export const onFetchLeasingStatsReportApi = ({startOfWeek, endOfWeek, locationId, cityId}) =>
  onFetch({
    url: `/jarvis_api/management/leasing_stats/?week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&loc_id=${locationId}&city_id=${cityId}`,
    method: 'GET',
  });

export const onFetchLeasingPerformanceReportApi = ({startOfWeek, endOfWeek, locationId}) =>
  onFetch({
    url: `/jarvis_api/management/leasing_performance/?week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&loc_id=${locationId}`,
    method: 'GET',
  });
export const onFetchLeasingPerformanceRentApi = ({startOfWeek, endOfWeek, locationId}) =>
  onFetch({
    url: `/jarvis_api/management/leasing_performance_expected_rent/${startOfWeek},${endOfWeek},${locationId}/`,
    method: 'GET',
  });
export const onFetchLeasingPerformanceLossesApi = ({startOfWeek, endOfWeek, locationId}) =>
  onFetch({
    url: `/jarvis_api/management/leasing_performance_losses/${startOfWeek},${endOfWeek},${locationId}/`,
    method: 'GET',
  });
export const onFetchLocationLevelUberReportApi = ({startDate, endDate}) =>
  onFetch({
    url: `/jarvis_api/management/citywise_uber_stats/${startDate},${endDate}/`,
    method: 'GET',
  });
export const onFetchCarEligibilityReportApi = ({startDate, endDate}) =>
  onFetch({
    url: `/jarvis_api/management/car_eligibility_report/?week_start_date=${startDate}&week_end_date=${endDate}`,
    method: 'GET',
  });
export const onFetchRecruitmentReportApi = ({startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/management/recruitment_report/${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchRecoveryReportApi = ({startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/management/revshare_recovery/?week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
  });
export const onFetchLeasingReportDash = ({startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/management/leasing_recovery/?week_start_date=${startOfWeek}&week_end_date=${endOfWeek}/`,
    method: 'GET',
  });

export const onFetchLocationStatsApi = (basis, locId, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/management/location_stats/?basis=${basis}&loc_id=${locId}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
  });

export const onFetchLocationStatsLeasingApi = ({startOfWeek, endOfWeek}, locId) =>
  onFetch({
    url: `/jarvis_api/management/location_stats_leasing/${startOfWeek},${endOfWeek},${locId}/`,
    method: 'GET',
  });

export const onFetchLocStatsQualifiedCarsApi = ({startOfWeek, endOfWeek}, locId) =>
  onFetch({
    url: `/jarvis_api/management/qualified_cars/${startOfWeek},${endOfWeek},${locId}/`,
    method: 'GET',
  });
export const onFetchLocStatsLeasQualifiedCarsApi = ({startOfWeek, endOfWeek}, locId) =>
  onFetch({
    url: `/jarvis_api/management/leasing_qualified_cars/${startOfWeek},${endOfWeek},${locId}/`,
    method: 'GET',
  });
export const onFetchAvgUtilisationApi = year =>
  onFetch({url: `/jarvis_api/management/average_utilization/${year}/`, method: 'GET'});
export const onFetchShpvApi = (basis, {locationId, startDate, endDate}) =>
  onFetch({
    url: `/jarvis_api/management/shpv_reports/?basis=${basis}&loc_id=${locationId}&week_start_date=${startDate}&week_end_date=${endDate}`,
    method: 'GET',
  });
export const onFetchShpvDriverHrsApi = ({startDate, endDate, locationId}) =>
  onFetch({
    url: `/jarvis_api/management/shpv_driver_hours/${startDate},${endDate},${locationId}/`,
    method: 'GET',
  });
export const onFetchShpvCdRateApi = ({startDate, endDate, locationId}) =>
  onFetch({
    url: `/jarvis_api/management/shpv_cd_rate/${startDate},${endDate},${locationId}/`,
    method: 'GET',
  });
export const onFetchShpvCombinedApi = ({startDate, endDate, locationId}) =>
  onFetch({
    url: `/jarvis_api/management/shpv_combined/${startDate},${endDate},${locationId}/`,
    method: 'GET',
  });
export const onFetchShpvDetailedApi = ({startDate, endDate, locationId}) =>
  onFetch({
    url: `/jarvis_api/management/shpv_detailed/${startDate},${endDate},${locationId}/`,
    method: 'GET',
  });
export const onFetchAttritionReportApi = ({month, locationId}) =>
  onFetch({
    url: `/jarvis_api/management/driver_attrition/${month},${locationId}/`,
    method: 'GET',
  });
export const onFetchShiftWiseDriverDataApi = ({startDate, locationId}) =>
  onFetch({
    url: `/jarvis_api/management/shiftwise_driver_data/${startDate},${locationId}/`,
    method: 'GET',
  });
export const onFetchShiftWiseDriverDetailedApi = ({startDate, locationId}) =>
  onFetch({
    url: `/jarvis_api/management/shiftwise_driver_detailed/${startDate},${locationId}/`,
    method: 'GET',
  });
export const onFetchPeakTimingsApi = ({startDate, locationId}) =>
  onFetch({
    url: `/jarvis_api/management/peak_timing/${startDate},${locationId}/`,
    method: 'GET',
  });
export const onFetchDriverShiftTimingsApi = ({startOfWeek, endOfWeek}, locationId) =>
  onFetch({
    url: `/jarvis_api/management/driver_timing_summary/${startOfWeek},${endOfWeek},${locationId}/`,
    method: 'GET',
  });
export const onFetchOlaDashApi = ({startOfWeek, endOfWeek, locationId, cityId}) =>
  onFetch({
    url: `/jarvis_api/management/ola_dash/?week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&loc_id=${locationId}&city_id=${cityId}`,
    method: 'GET',
  });
export const onFetchWeeklyRecoveryRevApi = ({startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/management/weekly_recovery_rev_share/${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchWeeklyRecoveryLeasingApi = ({startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/management/weekly_recovery_leasing/${startOfWeek},${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchWeeklyRecoveryApi = ({startOfWeek, endOfWeek}, basis, type) =>
  onFetch({
    url: `/jarvis_api/management/weekly_recovery/?week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&basis=${basis}&revenue_type=${type}`,
    method: 'GET',
  });
// EIP Dashboard
export const onFetchEipDashData = ({partnerIDValue}, {startOfWeek, endOfWeek}, signal) =>
  onFetch({
    url: `/jarvis_api/eip/dash_data/?partner_id=${partnerIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
    signal,
  });
export const onFetchEipCarAllocationReport = ({partnerIDValue}, {startOfWeek, endOfWeek}, signal) =>
  onFetch({
    url: `/jarvis_api/eip/car_allocation_report?partner_id=${partnerIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
    signal,
  });
export const onFetchDriverCarAllocationEipApi = ({partnerIDValue}, date) =>
  onFetch({
    url: `/jarvis_api/api/driver-car-allocation/?driver_id=${partnerIDValue}&for_date=${date}`,
    method: 'GET',
  });
export const onFetchPhonePeEip = ({partnerIDValue}, {startOfWeek, endOfWeek}, signal) =>
  onFetch({
    url: `/jarvis_api/eip/phonepe_report/?partner_id=${partnerIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
    signal,
  });
export const onFetchDeadKmEip = (partner, {startOfWeek, endOfWeek}, basis, carOrPartner, signal) =>
  onFetch({
    url: `/jarvis_api/eip/dead_kms/?partner_id=${partner}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&basis=${basis}&${carOrPartner}`,
    method: 'GET',
    signal,
  });
export const onFetchHelpCenterEip = (partner, basis, signal) =>
  onFetch({
    url: `/jarvis_api/eip/escalations_list/?driver_id=${partner}&basis=${basis}`,
    method: 'GET',
    signal,
  });
export const onFetchEipHisaabSummary = ({partnerIDValue}, {startOfWeek, endOfWeek}, signal) =>
  onFetch({
    url: `/jarvis_api/eip/hisaab_summary/?partner_id=${partnerIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}/`,
    method: 'GET',
    signal,
  });
export const onFetchWeeklyCompareEipReportApi = (partner, {startOfWeek, endOfWeek}, signal) =>
  onFetch({
    url: `/jarvis_api/eip/weekly_compare_report/?partner_id=${partner}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
    signal,
  });
export const onFetchEipApprovePenaltyApi = ({startOfWeek, endOfWeek, partnerIDValue, basis}) =>
  onFetch({
    url: `/jarvis_api/eip/temp_driver_penalty_fetch/?week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&partner_id=${partnerIDValue}&basis=${basis}`,
    method: 'GET',
  });
export const onFetchEipDriverHisaabApi = ({partnerIDValue}, {startOfWeek, endOfWeek}, basis) =>
  onFetch({
    url: `/jarvis_api/eip/driver_hisaab/?partner_id=${partnerIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&basis=${basis}`,
    method: 'GET',
  });
export const onFetchEipDriverHisaabAllotmentApi = ({partnerIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/eip/driver_hisaab_allotment/?partner_id=${partnerIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
  });
export const onFetchEipDriverHisaabOlaTransApi = ({partnerIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/eip/driver_hisaab_ola_transactions/?partner_id=${partnerIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchEipWeeklyDataReportApi = ({partnerID, startOfWeek, endOfWeek, basis}) =>
  onFetch({
    url: `/jarvis_api/eip/weekly_data/?partner_id=${partnerID}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&basis=${basis}`,
    method: 'GET',
  });
export const onFetchEipDailyDataReportApi = ({partnerID, startDate, endDate}) =>
  onFetch({
    url: `/jarvis_api/eip/daily_data/?partner_id=${partnerID}&date=${endDate}&week_start_date=${startDate}`,
    method: 'GET',
  });
export const onFetchEipLiveDataOnlineReportApi = ({partnerID, startDate, endDate}) =>
  onFetch({
    url: `/jarvis_api/eip/live_data/?partner_id=${partnerID}&date=${endDate}&week_start_date=${startDate}`,
    method: 'GET',
  });
export const onFetchEipWeeklyReportApi = ({partnerIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/eip/weekly_report/?partner_id=${partnerIDValue}&week_start_date=${startOfWeek}&week_end_date=${endOfWeek}`,
    method: 'GET',
  });
export const onFetchEipIncentiveQwsApi = ({partnerIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/eip/incentive_qualification_weekly_summary/?partner_id=${partnerIDValue}&start_date=${startOfWeek}&end_date=${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchEipIncentiveQws2Api = ({partnerIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/eip/incentive_qualification_summary/?partner_id=${partnerIDValue}&start_date=${startOfWeek}&end_date=${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchEipIncentiveQws3Api = ({partnerIDValue}, {startOfWeek, endOfWeek}) =>
  onFetch({
    url: `/jarvis_api/eip/incentive_qualified_detailed/?partner_id=${partnerIDValue}&start_date=${startOfWeek}&end_date=${endOfWeek}/`,
    method: 'GET',
  });
export const onFetchTeamStatsCompareApi = ({team_ids, loc_id, start_date, end_date, revenue_type}) =>
  onFetchStagJarvis({
    url: `/teams/team-stats?team_ids=${team_ids}&loc_id=${loc_id}&start_date=${start_date}&end_date=${end_date}&revenue_type=${revenue_type}`,
    method: 'GET',
  });

// Jarvis
export const onFetchManagersApi = manager => {
  let url = `/teams/users/?pattern=${manager.toLowerCase()}`;
  return onFetchStagJarvis({url});
};

export const onFetchJarvisLocationNamesApi = () =>
  onFetchStagJarvis({url: '/dropdown/company-locations', method: 'GET'});
export const onFetchJarvisRevenueTypeApi = () => onFetchStagJarvis({url: '/dropdown/payment_type', method: 'GET'});
export const onFetchJarvisPaymentModelsApi = () =>
  onFetchStagJarvis({url: '/dropdown/revenue_type_name', method: 'GET'});

export const onFetchPositivePayoutApi = (week, rev_type, basis) =>
  onPostProd({
    url: `/driver/positive_payout/?week=${week}&rev_type=${rev_type}${basis}`,
    method: 'GET',
  });

export const onPostPositivePayout = postData =>
  onPostProd({url: '/driver/positive_payout/', method: 'POST', data: postData});

export const onFetchReportsDowloadDateApi = ({recordType, startDate, endDate}) =>
  onFetchStagJarvis({
    url: `/driver/account-reports?records_of=${recordType}&start_date=${startDate}&end_date=${endDate}`,
    method: 'GET',
  });

export const onFetchUberWeeklyDataApi = ({startDate, endDate}) =>
  onFetchStagJarvis({
    url: `/driver/weekly_data_download?start_date=${startDate}&end_date=${endDate}`,
    method: 'GET',
  });

export const onFetchWeeklyUploadApi = ({week, revenueType, checkboxChecked}) =>
  onFetchStagJarvis({
    url: `/driver/upload_daily_to_weekly_data?week=${week}&revenue_type=${revenueType}&checkbox_checked=${checkboxChecked}`,
    method: 'GET',
  });

export const onFetchPaymentReport1Api = ({report_type, location, payment_model, dayDate}) =>
  onFetchStagJarvis({
    url: `/driver/driver_payment_report?report_type=${report_type}&date=${dayDate}&location=${location}&payment_model_type=${payment_model}`,
    method: 'GET',
  });

export const onFetchPaymentReport2Api = ({report_type, location, payment_model, weekDate}) =>
  onFetchStagJarvis({
    url: `/driver/driver_payment_report?report_type=${report_type}&week_number=${weekDate}&location=${location}&payment_model_type=${payment_model}`,
    method: 'GET',
  });

export const onFetchPaymentReport3Api = ({report_type, location, payment_model, weekStartDate, weekEndDate}) =>
  onFetchStagJarvis({
    url: `/driver/driver_payment_report?report_type=${report_type}&start_date=${weekStartDate}&end_date=${weekEndDate}&location=${location}&payment_model_type=${payment_model}`,
    method: 'GET',
  });
export const onFetchReportsDowloadWeekApi = ({recordType, week}) =>
  onFetchStagJarvis({
    url: `/driver/account-reports?records_of=${recordType}&week=${week}`,
    method: 'GET',
  });

export const onFetchCrmReportExcelApi = ({startDate, endDate}, {cityValue}) =>
  onFetchStagJarvis({
    url: `/driver/CRM_Report?start_date=${startDate}&end_date=${endDate}&city_id=${cityValue}`,
    method: 'GET',
  });

export const onFetchDocumentationFeeReportApi = (startDate, endDate) =>
  onFetchStagJarvis({
    url: `/driver/documentation_data_download?start_date=${startDate}&end_date=${endDate}`,
    method: 'GET',
  });

export const onFetchSecurityDepositReportApi = (startDate, endDate) =>
  onFetchStagJarvis({
    url: `/driver/securty_deposit_download?start_date=${startDate}&end_date=${endDate}`,
    method: 'GET',
  });

export const onPostDriverPenalty = postData =>
  onPostProd({url: '/garage/garage_upload/', method: 'POST', data: postData});

export const onPostLeasingUploadApi = postData =>
  onPostProd({
    url: '/lead_management/driver-lead-upload',
    method: 'POST',
    data: postData,
  });
export const onFetchCarManagementReportApi = (page, size, filters, {cityValue}) => {
  const url = modifyUrlForFiltering(`/car/car-management?page=${page}&page_size=${size}&city_id=${cityValue}`, filters);
  return onFetchStagJarvis({url});
};
export const onFetchSampleFile = type => {
  const url = modifyUrlForFiltering(`/common_utils/sample-file?type=${type}`);
  return onFetchStagJarvis({url});
};
export const onFetchRentalGuideApi = (page, size, filters, {cityValue}, type) => {
  const url = modifyUrlForFiltering(
    `/car/leasing_upload?page=${page}&page_size=${size}&city_id=${cityValue}&type=${type}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const onFetchDriverEIPTransferApi = (page, size, filters, {cityValue}) => {
  const url = modifyUrlForFiltering(
    `/driver/driver-eip-revoke?city_id=${cityValue}&page=${page}&page_size=${size}`,
    filters
  );
  return onFetchStagJarvis({url});
};
export const onFetchActiveCarTransferReportApi = (page, size, filters, {cityValue}) => {
  const url = modifyUrlForFiltering(
    `/car/car-team-transfer?city_id=${cityValue}&type=${'ACTIVE'}&page=${page}&page_size=${size}&sort_key=created_at&sort_order=DESC`,
    filters
  );
  return onFetchStagJarvis({url});
};
export const onFetchHistoryCarTransferReportApi = (page, size, filters, {cityValue}) => {
  const url = modifyUrlForFiltering(
    `/car/car-team-transfer?city_id=${cityValue}&type=${'HISTORY'}&page=${page}&page_size=${size}&sort_key=created_at&sort_order=DESC`,
    filters
  );
  return onFetchStagJarvis({url});
};
export const onPostDriverEipRevokeApi = postData =>
  onPostProd({url: '/driver/driver-eip-revoke', method: 'POST', data: postData});

export const onPostDriverEipTransferApi = postData =>
  onPostProd({url: '/driver/driver-eip-transfer', method: 'POST', data: postData});

export const onFetchDriverEIPTransferEmpApi = ({cityValue}, searchEmp) =>
  onFetchStagJarvis({url: `/driver/driver-eip-transfer?city_id=${cityValue}&employee_id=${searchEmp}`, method: 'GET'});

export const onFetchUserAdditionalTeamAccessApi = (page, size, filters, {cityValue}) => {
  const url = modifyUrlForFiltering(
    `/teams/user_additional_teams_access?page=${page}&page_size=${size}&city=${cityValue}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const onFetchPaymentLinkApi = (page, size, filters) => {
  const url = modifyUrlForFiltering(`/payment/driver-payment-link?page=${page}&page_size=${size}`, filters);
  return onFetchStagJarvis({url});
};
export const onPostPaymentLinkApi = fd => onPostProd1({url: '/payment/driver-payment-link', method: 'POST', data: fd});

export const onFetchCarManagementRowIdApi = id => onFetchStagJarvis({url: `/car/car-management/${id}`, method: 'GET'});
export const onPostCarManagementReport = postData =>
  onPostProd({url: '/car/car-management', method: 'POST', data: postData});

export const onPatchCarManagementReport = (id, fd) =>
  onPatchProd({url: `/car/car-management/${id}`, method: 'PATCH', data: fd});

export const onPatchRentalGuide = (id, fd) =>
  onPatchProd({url: `/car/leasing_upload/${id}/`, method: 'PATCH', data: fd});

export const onPostUserAdditianal = fd =>
  onPostProd1({url: '/teams/user_additional_teams_access', method: 'POST', data: fd});

export const onFetchCityNamesApi = () => onFetchStagJarvis({url: '/fleet_apis/fleetCityList/', method: 'GET'});

export const onFetchDBTablesCheckDailyApi = (basis, date) =>
  onFetchStagJarvis({
    url: `/reports/reco-report/?record=${basis}&date=${date}`,
    method: 'GET',
  });

export const onFetchDBTablesCheckWeeklyApi = (basis, {weekDate}) =>
  onFetchStagJarvis({
    url: `/reports/reco-report/?record=${basis}&week_number=${weekDate}`,
    method: 'GET',
  });
export const onFetchJarvisDriverHisaabApi = date =>
  onFetchStagJarvis({
    url: `/driver/driver_hisab_reco_report?date=${date}`,
    method: 'GET',
  });
export const onFetchJarvisAdjustmentApi = ({startOfWeek, endOfWeek}) =>
  onFetchStagJarvis({
    url: `/driver/driver_adjustment_reco_report?start_date=${startOfWeek}&end_date=${endOfWeek}`,
    method: 'GET',
  });
export const onFetchUberApi = ({week}) =>
  onFetchStagJarvis({url: `/reports/uberdashboard/?week=${week}`, method: 'GET'});

export const onFetchPlatformTableRecoApi = ({week}) =>
  onFetchStagJarvis({url: `/reports/reports-dashboard/?week=${week}`, method: 'GET'});

export const onFetchCarCompanyListApi = () => onFetchStagJarvis({url: '/car/car-company', method: 'GET'});

export const onFetchCarModelListApi = id =>
  onFetchStagJarvis({url: `/dropdown/car_model_names?company_id=${id}`, method: 'GET'});

export const onFetchDriverCarModelListApi = () => onFetchStagJarvis({url: `/dropdown/car_model_names`, method: 'GET'});

export const onFetchCarCityListApi = () => onFetchStagJarvis({url: '/car/fleet-city', method: 'GET'});

export const onPostGPSUploadApi = postData => onPostProd({url: '/car/gps_upload', method: 'POST', data: postData});

export const onPostUUIDApi = postData =>
  onPostProd({url: '/driver/update_driver_UUID', method: 'POST', data: postData});

export const onPostUploadWeeklyLeasingOSApi = postData =>
  onPut({url: '/driver/upload-leasing-os', method: 'PUT', data: postData});

export const onPostUploadDailyLeasingOSApi = postData =>
  onPut({url: '/driver/upload-leasing-daily-os', method: 'PUT', data: postData});

export const onPostUploadDailyRevShareOSApi = postData =>
  onPut({url: '/driver/upload-rev-share-daily-os', method: 'PUT', data: postData});

export const onPostAdjustmentsUploadApi = postData =>
  onPostProd({url: '/driver/rav-share-upload', method: 'POST', data: postData});

export const onPostDailyTripsUploadApi = postData =>
  onPostProd({url: '/car/populate-uber-dead-km', method: 'POST', data: postData});

export const onPostAddWeeklyUberDataApi = postData =>
  onPostProd({url: '/driver/add_payout_weekly_data', method: 'POST', data: postData});

export const onPostdailyUberRawtripsUploadApi = postData =>
  onPostProd({url: '/car/uber_upload_summarize_data', method: 'POST', data: postData});

export const onPostPayoutDataUploadApi = postData =>
  onPostProd({url: '/driver/payout_process_data', method: 'POST', data: postData});

export const onPatchCarIncentive = (id, fd) =>
  onPatchProd({url: `/car/car_incentive/${id}/`, method: 'PATCH', data: fd});

export const onPostDriverDeposit = postData =>
  onPatchProd({url: `/driver/deposit_rule`, method: 'POST', data: postData});

export const onPatchDriverIncentive = (id, fd) =>
  onPatchProd({url: `/driver/driver_incentive/${id}/`, method: 'PATCH', data: fd});

export const onPostCarIncentiveApi = postData =>
  onPostProd({url: '/car/car_incentive', method: 'POST', data: postData});

export const onPostLeasingDriverIncentiveApi = postData =>
  onPostProd({url: '/driver/driver_incentive', method: 'POST', data: postData});

export const onFetchCarIncentiveApi = (page, size, filters, {cityValue}, type) => {
  const url = modifyUrlForFiltering(
    `/car/car_incentive?page=${page}&page_size=${size}&city_id=${cityValue}&type=${type}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const onFetchDriverDepositApi = (page, size, filters, loc) => {
  const url = modifyUrlForFiltering(`/driver/deposit_rule?page=${page}&page_size=${size}&loc_id=${loc}`, filters);
  return onFetchStagJarvis({url});
};

export const onFetchLeasingDriverIncentiveApi = (page, size, filters, {cityValue}, basis) => {
  const url = modifyUrlForFiltering(
    `/driver/driver_incentive?page=${page}&page_size=${size}&city_id=${cityValue}&type=${'leasing'}&basis=${basis}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const onFetchRevShareDriverIncentiveApi = (page, size, filters, {cityValue}, basis) => {
  const url = modifyUrlForFiltering(
    `/driver/driver_incentive?page=${page}&page_size=${size}&city_id=${cityValue}&type=${'revshare'}&basis=${basis}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const onPostLeasingUploadsApi = postData =>
  onPostProd({url: '/car/leasing_upload', method: 'POST', data: postData});
export const onPostOlaUploadDataApi = ({startOfWeek, endOfWeek}, basis, postData) =>
  onPostProd({
    url: `/car/ola-upload-data?week_start_date=${startOfWeek}&week_end_date=${endOfWeek}&upload_table=${basis}`,
    method: 'POST',
    data: postData,
  });
export const onFetchCarUnallocatedReportApi = (page, size, filters, {cityValue}) => {
  const url = modifyUrlForSpecificFiltering(
    `/car/car_unallocated?page=${page}&page_size=${size}&city_id=${cityValue}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const onFetchDriverDetails = employeeId => {
  const url = employeeId ? `/driver/?employee_id=${employeeId}` : '/driver/';
  return onFetchStagJarvis({
    url,
    method: 'GET',
  });
};

export const onFetchTeamNameDropdownApi = ({cityValue}) =>
  onFetchStagJarvis({
    url: `/dropdown/team_name?city_id=${cityValue}`,
    method: 'GET',
  });
export const onFetchManagerNameDropdownApi = id =>
  onFetchStagJarvis({
    url: `/dropdown/team_lead_name?team_id=${id}`,
    method: 'GET',
  });
export const onFetchLocationData = () =>
  onFetchStagJarvis({
    url: `/dropdown/company-locations`,
    method: 'GET',
  });
export const onFetchCarUnallocatedReportExcelApi = ({cityValue}) =>
  onFetchStagJarvis({
    url: `/car/car_unallocated?city_id=${cityValue}`,
    method: 'GET',
  });
export const onFetchNonEverestVehicleReportApi = (page, size, filters, date) => {
  const url = modifyUrlForSpecificFiltering(
    `/car/non_everest_vehicle_data_download?page=${page}&page_size=${size}&start_date=${date}`,
    filters
  );
  return onFetchStagJarvis({url});
};
export const onFetchNonEverestVehicleReportExcelApi = date =>
  onFetchStagJarvis({
    url: `/car/non_everest_vehicle_data_download?&start_date=${date}`,
    method: 'GET',
  });
export const onFetchPenaltyReportDownloadApi = ({penaltyType, week}) =>
  onFetchStagJarvis({
    url: `/driver/driver_penalty_download?week_number=${week}&penalty_type=${penaltyType}`,
    method: 'GET',
  });

// DRIVER Onboarding Management
export const onPostLoginSendOtp = payload =>
  onPostStagJarvis({
    url: '/fleet_apis/sendotp/',
    method: 'POST',
    data: payload,
  });
export const onPutDriverPersonDetails = (id, payload) =>
  onPut({
    url: `/fleet_apis/personalDetail/${id}/`,
    method: 'PUT',
    data: payload,
  });
export const onPostDriverUberdetails = payload =>
  onPostStagJarvis({
    url: '/fleet_apis/postuberdetail/',
    method: 'POST',
    data: payload,
  });
export const onPost1DriverUberdetails = payload =>
  onPost1StagJarvis({
    url: '/fleet_apis/postuberdetail/',
    method: 'POST',
    data: payload,
  });
export const onPost1DriverUberdetailsSkip = payload =>
  onPost1StagJarvis({
    url: '/fleet_apis/postuberdetail/?skip=yes',
    method: 'POST',
    data: payload,
  });
export const onPatchDriverUberdetails = (id, payload) =>
  onPatchStagJarvis({
    url: `/fleet_apis/postuberdetail/${id}/`,
    method: 'PATCH',
    data: payload,
  });
export const onPostDriverReference = payload =>
  onPostStagJarvis({
    url: '/fleet_apis/reference/',
    method: 'POST',
    data: payload,
  });
export const onPostDriverReferenceSkip = payload =>
  onPostStagJarvis({
    url: '/fleet_apis/reference/?skip=yes',
    method: 'POST',
    data: payload,
  });
export const onPatchDriverReference = payload =>
  onPatchStagJarvis({
    url: '/fleet_apis/reference/',
    method: 'PATCH',
    data: payload,
  });
export const onPostAdministrativeDetails = payload =>
  onPostStagJarvis({
    url: '/fleet_apis/administrativedetails/',
    method: 'POST',
    data: payload,
  });
export const onPatchAdministrativeDetails = (id, payload) =>
  onPatchStagJarvis({
    url: `/fleet_apis/administrativedetails/${id}/`,
    method: 'PATCH',
    data: payload,
  });
export const onFetchDriverApplicants = (size, page, city, search) =>
  onFetchStagJarvis({
    url: `/fleet_apis/applicants/?content=${size}&page=${page}&cityId=${city}&search=${search}`,
    method: 'GET',
  });
export const onFetchDriverRejected = (size, page, city, search) =>
  onFetchStagJarvis({
    url: `/fleet_apis/applicants/Rejected/?content=${size}&page=${page}&cityId=${city}&search=${search}`,
    method: 'GET',
  });
export const onPostBankDetails = payload =>
  onPostStagJarvis({
    url: '/fleet_apis/postbankdetail/',
    method: 'POST',
    data: payload,
  });
export const onPostBankDetailsSkip = payload =>
  onPostStagJarvis({
    url: '/fleet_apis/postbankdetail/?skip=yes',
    method: 'POST',
    data: payload,
  });
export const onPatchBankDetails = (id, payload) =>
  onPatchStagJarvis({
    url: `/fleet_apis/postbankdetail/${id}/`,
    method: 'PATCH',
    data: payload,
  });
export const onPostDocuments = payload =>
  onPostStagJarvis({
    url: '/fleet_apis/documentdetails/',
    method: 'POST',
    data: payload,
  });
export const onfetchAvailableAreaList = id =>
  onFetchStagJarvis({
    url: `/fleet_apis/availableareaslist/${id}`,
    method: 'GET',
  });
export const onfetchAvailableArea = () => onFetchStagJarvis({url: '/fleet_apis/availablearea/', method: 'GET'});

export const onFetchDriverPersonalDetails = id =>
  onFetchStagJarvis({url: `/fleet_apis/personalDetail/${id}/`, method: 'GET'});
export const onFetchDocumentDetails = id =>
  onFetchStagJarvis({url: `/fleet_apis/documentdetails/${id}/`, method: 'GET'});
export const getBankList = () => onFetchStagJarvis({url: '/fleet_apis/banklist/', method: 'GET'});
export const onFetchBankDetails = id => onFetchStagJarvis({url: `/fleet_apis/postbankdetail/${id}/`, method: 'GET'});
export const onFetchAdministrativeDetails = id =>
  onFetchStagJarvis({
    url: `/fleet_apis/administrativedetails/${id}/`,
    method: 'GET',
  });
export const onPostOnboarFormError = payload =>
  onPost1StagJarvis({
    url: '/fleet_apis/postonboarding/',
    method: 'POST',
    data: payload,
  });
export const onFetchApplicationErrorDetails = id =>
  onFetchStagJarvis({url: `/fleet_apis/postonboarding/${id}/`, method: 'GET'});
export const fetchDriverTestList = (size, page, city, search) =>
  onFetchStagJarvis({
    url: `/fleet_apis/drivertestlist/?content=${size}&page=${page}&cityId=${city}&search=${search}`,
    method: 'GET',
  });
export const onPostdrivertestpost = payload =>
  onPostStagJarvis({
    url: '/fleet_apis/drivertestpost/',
    method: 'POST',
    data: payload,
  });
export const onPostdrivertrainingstatus = payload =>
  onPostStagJarvis({
    url: '/fleet_apis/trainingstatus/',
    method: 'POST',
    data: payload,
  });
export const fetchDepositDetials = (size, page, city, search) =>
  onFetchStagJarvis({
    url: `/fleet_apis/drivingdepositeget/?content=${size}&page=${page}&cityId=${city}&search=${search}`,
    method: 'GET',
  });
export const onPostdriverDeposite = payload =>
  onPostStagJarvis({
    url: '/fleet_apis/driverdepositepost/',
    method: 'POST',
    data: payload,
  });
export const fetchDriverContractList = (size, page, city, search) =>
  onFetchStagJarvis({
    url: `/fleet_apis/drivercontractlist/?content=${size}&page=${page}&cityId=${city}&search=${search}`,
    method: 'GET',
  });
export const onfetchfleetCityList = () => onFetchStagJarvis({url: '/fleet_apis/fleetCityList/', method: 'GET'});
export const onfetchfleetCityLocationsList = value =>
  onFetchStagJarvis({url: `/common_utils/lov?lov_names=company_location&column=city_id&value=${value}`, method: 'GET'});
export const onfetchAgentNameList = () => onFetchStagJarvis({url: '/fleet_apis/agentrole/', method: 'GET'});
export const onPatchDriverPostonboarding = (id, payload) =>
  onPatchStagJarvis({
    url: `/fleet_apis/postonboarding/${id}/`,
    method: 'PATCH',
    data: payload,
  });
export const fetchDrivercontractsignList = (size, page, city, search) =>
  onFetchStagJarvis({
    url: `/fleet_apis/contractsign/?content=${size}&page=${page}&cityId=${city}&search=${search}`,
    method: 'GET',
  });
export const onfetchIncompleteDriverList = (size, page, city, search) =>
  onFetchStagJarvis({
    url: `/fleet_apis/driverincompleteonboardstatus/?content=${size}&page=${page}&cityId=${city}&search=${search}`,
    method: 'GET',
  });
export const onfetchDriverlist = (size, page, city, search) =>
  onFetchStagJarvis({
    url: `/fleet_apis/alldriverlist/?content=${size}&page=${page}&cityId=${city}&search=${search}`,
    method: 'GET',
  });
export const onFetchReference = id => onFetchStagJarvis({url: `/fleet_apis/reference/${id}/`, method: 'GET'});
export const onfetchCityList = () => onFetchStagJarvis({url: '/fleet_apis/citylist/', method: 'GET'});
export const getUserName = id => onFetchStagJarvis({url: `/fleet_apis/drivermini/${id}`, method: 'GET'});
export const onfetchAgentDefaultCity = () => onFetchStagJarvis({url: '/fleet_apis/agentCity/', method: 'GET'});
export const onfetchDriverReport = () => onFetchStagJarvis({url: '/fleet_apis/report/', method: 'GET'});

// New onboarding Flow
export const onFetchFindDriver = (input_type, input_number) =>
  onFetchStagJarvis({
    url: `/driver/find-driver?${input_type}=${input_number}`,
    method: 'GET',
  });
export const onFetchDriverRegisterOtp = mobile =>
  onFetchStagJarvis({
    url: `/driver/details-and-sendotp?mobile=${mobile}`,
    method: 'GET',
  });
export const onFetchDriverRegisterGetOtp = mobile =>
  onFetchStagJarvis({
    url: `/common_utils/fetch-otp?mobile=${mobile}`,
    method: 'GET',
  });
export const onPostDriverVerifyOtp = payload =>
  onPostStagJarvis({url: '/common_utils/user', method: 'POST', data: payload});
export const onFetchDriverSchedule = id =>
  onFetchStagJarvis({
    url: `/driver/schedule-visit?driver_id=${id}`,
    method: 'GET',
  });
export const onPostSubmitSchedule = payload =>
  onPost1StagJarvis({
    url: '/driver/schedule-visit',
    method: 'POST',
    data: payload,
  });
export const onPatchScheduleInterview = (id, payload) =>
  onPost1StagJarvis({
    url: `/driver/schedule-visit/${id}`,
    method: 'PATCH',
    data: payload,
  });
export const onFetchDriverListDetail = id => onFetchStagJarvis({url: `/driver?auth_user_id=${id}`, method: 'GET'});
export const onPostDriverCreation = payload => onPost1StagJarvis({url: '/driver/', method: 'POST', data: payload});
export const onPatchDriverCreation = (id, payload) =>
  onPost1StagJarvis({url: `/driver/${id}`, method: 'PATCH', data: payload});
export const onPatchDriverRejection = (id, remarks) =>
  onPost1StagJarvis({url: `/driver/reject-driver/${id}?remarks=${remarks}`, method: 'PATCH'});
export const onfetchDriverCreation = id => onFetchStagJarvis({url: `/driver/${id}`, method: 'GET'});
export const onFetchOnboardingStatus = id =>
  onFetchStagJarvis({
    url: `/driver/onboarding-status?driver_id=${id}`,
    method: 'GET',
  });
export const onfetchOnboardingDriverList = (city, page, size, filters) => {
  const url = modifyUrlForFiltering(`/driver/?city_id=${city}&page=${page}&page_size=${size}`, filters);
  return onFetchStagJarvis({url});
};
export const onPostDriverProfile = payload =>
  onPostStagJarvis({url: '/driver/onboarding-profile', method: 'POST', data: payload});
export const onFetchDriverProfile = id =>
  onFetchStagJarvis({url: `/driver/onboarding-profile?driver_id=${id}`, method: 'GET'});

export const onPatchDriverProfile = (id, payload) =>
  onPostStagJarvis({url: `/driver/onboarding-profile/${id}`, method: 'PATCH', data: payload});
export const onfetchAddressType = value =>
  onFetchStagJarvis({url: `/common_utils/lov?lov_names=everest_lookup&column=type&value=${value}`, method: 'GET'});
export const onPostSaveDriverAddress = payload =>
  onPostStagJarvis({url: '/driver/address', method: 'POST', data: payload});
export const onPatchDriverAddress = (id, payload) =>
  onPostStagJarvis({url: `/driver/address/${id}`, method: 'PATCH', data: payload});
export const onFetchDriverAddrerss = id => onFetchStagJarvis({url: `/driver/address?driver_id=${id}`, method: 'GET'});
export const onFetchDriverBankDetails = id =>
  onFetchStagJarvis({url: `/driver/bank-account?driver_id=${id}`, method: 'GET'});
export const onFetchUberDetails = id => onFetchStagJarvis({url: `/driver/uber-profile?driver_id=${id}`, method: 'GET'});
export const onPostUberdetails = payload =>
  onPost1StagJarvis({url: '/driver/uber-profile', method: 'POST', data: payload});
export const onPatchUberdetails = (id, payload) =>
  onPost1StagJarvis({url: `/driver/uber-profile/${id}`, method: 'PATCH', data: payload});
export const onFetchDriverReference = id =>
  onFetchStagJarvis({url: `/driver/reference?driver_id=${id}`, method: 'GET'});
export const onPostReferenceDetails = payload =>
  onPost1StagJarvis({url: '/driver/reference', method: 'POST', data: payload});
export const onPatchReferenceDetails = payload =>
  onPost1StagJarvis({url: '/driver/reference', method: 'PATCH', data: payload});
export const onfetchRejectIssueList = () =>
  onFetchStagJarvis({
    url: '/common_utils/lov?lov_names=everest_lookup&column=type&value=driver-reject-reason',
    method: 'GET',
  });

export const fetchDriverTraining = (city, page, size, filters) => {
  let url;
  url = modifyUrlForFiltering(`/driver/training?city_id=${city}&page=${page}&page_size=${size}`, filters);
  return onFetchStagJarvis({url});
};

export const fetchDrivingTest = (city, page, size, filters) => {
  let url;
  url = modifyUrlForFiltering(`/driver/driving-test?city_id=${city}&page=${page}&page_size=${size}`, filters);
  return onFetchStagJarvis({url});
};
export const fetchDriverTestById = id =>
  onFetchStagJarvis({
    url: `/driver/driving-test?driver_id=${id}`,
    method: 'GET',
  });
export const onPatchDriveTestUpdate = (id, payload) =>
  onPost1StagJarvis({
    url: `/driver/driving-test/${id}`,
    method: 'PATCH',
    data: payload,
  });
export const fetchDriverTrainingById = id =>
  onFetchStagJarvis({url: `/driver/training?driver_id=${id}`, method: 'GET'});
export const onPatchDriveTrainingUpdate = (id, payload) =>
  onPost1StagJarvis({
    url: `/driver/training/${id}`,
    method: 'PATCH',
    data: payload,
  });
export const onfetchDriverContractList = (city, page, size, filters) => {
  const url = modifyUrlForFiltering(`/driver/contract?city_id=${city}&page=${page}&page_size=${size}`, filters);
  return onFetchStagJarvis({url});
};
export const fetchOnboardDocumentationChargeDetails = (loc_id, page, size, filters) => {
  const url = modifyUrlForFiltering(
    `/driver/documentation_charge_list?loc_id=${loc_id}&is_amount_paid=1&page=${page}&page_size=${size}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const fetchOnboardDocumentationChargeDetailsByDriver = driver_id => {
  const url = modifyUrlForFiltering(`/driver/documentation_charge?driver_id=${driver_id}`);
  return onFetchStagJarvis({url, method: 'GET'});
};

export const fetchDocumentationCharges = (loc, model, revenue) => {
  const url = modifyUrlForFiltering(
    `/fleet/documentation-charges/config?loc=${loc}&model=${model}&rev_type=${revenue}`
  );
  return onFetchStagJarvis({url, method: 'GET'});
};

export const onPostDocumentationChargeApi = data =>
  onPostProd1({url: '/driver/documentation_charge', method: 'POST', data});
export const onUpdateDocumentationChargeApi = (data, id) =>
  onPostProd1({
    url: `/driver/documentation_charge/${id}`,
    method: 'PATCH',
    data,
  });
export const onFetchDriverContract = id => onFetchStagJarvis({url: `/driver/contract?driver_id=${id}`, method: 'GET'});
export const onPatchDriverContract = (id, payload) =>
  onPostStagJarvis({
    url: `/driver/contract/${id}`,
    method: 'PATCH',
    data: payload,
  });
export const onFetchUserList = () => onFetchStagJarvis({url: '/common_utils/user', method: 'GET'});
// export const fetchKuberListing = (size, page) => onFetchStagJarvis({ url: `/driver/kuber-contract?page_size=${size}&page=${page}`, method: 'GET' });
export const fetchKuberListing = (city, page, size, filters) => {
  const url = modifyUrlForFiltering(`/driver/kuber-contract?city_id=${city}&page=${page}&page_size=${size}`, filters);
  return onFetchStagJarvis({url});
};
export const fetchDriverBlacklistCurrent = (city, page, size, filters) => {
  const url = modifyUrlForFiltering(
    `/driver/driver_blacklist?city_id=${city}&check=true&page=${page}&page_size=${size}`,
    filters
  );
  return onFetchStagJarvis({url});
};
export const fetchDriverBlacklistHistorical = (city, page, size, filters) => {
  const url = modifyUrlForFiltering(
    `/driver/driver_blacklist_report?city_id=${city}&page=${page}&page_size=${size}`,
    filters
  );
  return onFetchStagJarvis({url});
};
export const onPostDriverBlacklist = (driver, payload) =>
  onPost1StagJarvis({
    url: `/driver/driver_blacklist?driver_id=${driver}`,
    method: 'POST',
    data: payload,
  });
export const onPostDriveTraining = payload =>
  onPost1StagJarvis({url: '/driver/training', method: 'POST', data: payload});
export const onPostDriveTest = payload =>
  onPost1StagJarvis({
    url: '/driver/driving-test',
    method: 'POST',
    data: payload,
  });
export const onPostDriverContract = payload =>
  onPostStagJarvis({url: '/driver/contract', method: 'POST', data: payload});
export const onboardFetchSecurityDepositApi = (loc_id, page, size, filters) => {
  const url = modifyUrlForFiltering(
    `/driver/security-deposit-list?loc_id=${loc_id}&page=${page}&page_size=${size}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const fetchSecurityDepositDetails = (loc, model, revenue) =>
  onFetchStagJarvis({
    url: `/fleet/security-deposit/config?loc=${loc}&model=${model}&rev_type=${revenue}`,
    method: 'GET',
  });

export const fetchSecurityDepositDetailsByPaymentId = payment_id =>
  onFetchStagJarvis({
    url: `/driver/security-deposit?id=${payment_id}`,
    method: 'GET',
  });

export const fetchSecurityDepositDetailsByDriver = driver_id =>
  onFetchStagJarvis({
    url: `/driver/security-deposit?driver_id=${driver_id}`,
    method: 'GET',
  });

export const createSecurityDeposit = data => onPostProd1({url: '/driver/security-deposit', method: 'POST', data});

export const updateSecurityDeposit = (id, data) =>
  onPatchProd({url: `/driver/security-deposit/${id}`, method: 'PATCH', data});

export const fetchDocumentationDepositDetails = (loc, model, revenue) =>
  onFetchStagJarvis({
    url: `/fleet/documentation-charges/config?loc=${loc}&model=${model}&rev_type=${revenue}`,
    method: 'GET',
  });

export const fetchDocumentationDepositDetailsById = driver_id =>
  onFetchStagJarvis({
    url: `/driver/documentation_charge?driver_id=${driver_id}`,
    method: 'GET',
  });

// export const fetchDocumentationDepositDetailsByPaymentId = (payment_id, driver_id) => onFetchStagJarvis({
//   url: `/driver/documentation_charge?id=${payment_id}&driver_id=${driver_id}`, method: 'GET',
// });

export const createDocumentationDeposit = data =>
  onPostProd1({url: '/driver/documentation_charge', method: 'POST', data});

export const updateDocumentationDeposit = (payment_id, data) =>
  onPatchProd({
    url: `/driver/documentation_charge/${payment_id}`,
    method: 'PATCH',
    data,
  });

export const onPostApproveApplication = id =>
  onPost1StagJarvis({
    url: `/driver/approve-application?driver_id=${id}`,
    method: 'POST',
  });
export const fetchPinCode = id =>
  onFetchStagJarvis({
    url: `/common_utils/pincode?pincode=${id}`,
    method: 'GET',
  });
export const onPostDriverKuber = payload =>
  onPostStagJarvis({url: '/driver/kuber-contract', method: 'POST', data: payload});

export const createSecurityDepositTopUp = (id, data) =>
  onPostProd1({
    url: `/driver/security-deposit/top-up/${id}`,
    method: 'POST',
    data,
  });

export const createSecurityDepositPaytmTopUp = data =>
  onPostProd1({
    url: '/driver/security-deposit',
    method: 'POST',
    data,
  });

export const updateSecurityDepositTopUp = (payment_id, data) =>
  onPatchProd({
    url: `/driver/security-deposit/top-up/${payment_id}`,
    method: 'PATCH',
    data,
  });

export const fetchSecurityDepositTopUpDetailsByPaymentId = payment_id =>
  onFetchStagJarvis({
    url: `/driver/security-deposit?id=${payment_id}&payment_type=SD_TOP`,
    method: 'GET',
  });

export const updatePaymentEntry = (url, data, id) => onPostProd1({url: `${url}/${id}`, method: 'POST', data});

export const fetchDriverInfo = employeeId => {
  const url = modifyUrlForFiltering(`/driver?employeeId=${employeeId}`);
  return onFetchStagJarvis({url, method: 'GET'});
};
export const fetchKuberData = driver_id => {
  const url = modifyUrlForFiltering(`/driver/kuber-contract?driver_id=${driver_id}`);
  return onFetchStagJarvis({url});
};

export const fetchContract = id =>
  onFetchStagJarvis({url: `/driver/contract_view?driver_id=${id}&type=${'Signed'}`, method: 'GET'});

export const fetchDriverScheduleList = (city, page, size, filters) => {
  const url = modifyUrlForFiltering(`/driver/schedule-visit?city_id=${city}&page=${page}&page_size=${size}`, filters);
  return onFetchStagJarvis({url});
};

export const fetchDriverWalkInList = (city, page, size, filters) => {
  const url = modifyUrlForFiltering(`/driver/walkin/?city_id=${city}&page=${page}&page_size=${size}`, filters);
  return onFetchStagJarvis({url});
};

export const onfetchDriverDocumentList = (city, page, size, filters) => {
  const url = modifyUrlForFiltering(
    `/driver/document-status-view?city_id=${city}&page=${page}&page_size=${size}`,
    filters
  );
  return onFetchStagJarvis({url});
};
export const fetchTeamList = (city, type, page, size, filters) => {
  const url = modifyUrlForFiltering(
    `/teams/update-team?city_id=${city}&type=${type}&page=${page}&page_size=${size}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const fetchGargeTeamList = (city, type, filters) => {
  const url = modifyUrlForFiltering(`/garage/team?city_id=${city}&type=${type}`, filters);
  return onFetchStagJarvis({url});
};

export const fetchTeamUsersList = (pattern, filters) => {
  const url = modifyUrlForFiltering(`/teams/users?pattern=${pattern}`, filters);
  return onFetchStagJarvis({url});
};

export const fetchCityLocations = (city, filters) => {
  const url = modifyUrlForFiltering(`/teams/locations?city=${city}`, filters);
  return onFetchStagJarvis({url});
};

export const createTeam = data => {
  return onPost1StagJarvis({url: '/teams/update-team', method: 'POST', data: data});
};

export const updateAuditTeam = (id, data) => {
  return onPatchStagJarvis({url: `/teams/audit-team/${id}`, method: 'PATCH', data: data});
};

export const createDMTeam = fd => onPostProd({url: '/teams/add-team', method: 'POST', data: fd});

export const updateTeam = data => {
  return onPatchStagJarvis({url: '/teams/update-team', method: 'PATCH', data: data});
};

export const fetchTeamDetails = (city, team_id, type, size, filters) => {
  const url = modifyUrlForFiltering(`/teams/update-team?city_id=${city}&team_id=${team_id}&type=${type}`, filters);
  return onFetchStagJarvis({url});
};

export const fetchRevenueTypeDetails = filters => {
  const url = modifyUrlForFiltering(`/dropdown/revenue_type_name?_type=query`, filters);
  return onFetchStagJarvis({url});
};

export const fetchBusinessTypeDetails = filters => {
  const url = modifyUrlForFiltering(`/dropdown/business_type_name?_type=query`, filters);
  return onFetchStagJarvis({url});
};
export const fetchDashboardDetails = filters => {
  const url = modifyUrlForFiltering(`/dropdown/dashboard_type_name?_type=query`, filters);
  return onFetchStagJarvis({url});
};

export const fetchAuditRoles = () => {
  const url = modifyUrlForFiltering(`/dropdown/role_type`);
  return onFetchStagJarvis({url});
};

export const fetchSubTeamsDetails = (revenueType, cityId) => {
  const url = `/teams/teams?revenue_type=${revenueType}&city_id=${cityId}`;
  return onFetchStagJarvis({url, method: 'GET'});
};

export const onFetchDriverFunnelListApi = (startDate, endDate, cityIds) =>
  onFetchStagJarvis({
    url: `/driver/driver-funnel-report?start_date=${startDate}&end_date=${endDate}&city_ids=${cityIds}`,
    method: 'GET',
  });

export const fetchUberDetailsList = (city, page, size, filters) => {
  const url = modifyUrlForFiltering(`/driver/uber-list-report?city_id=${city}&page=${page}&page_size=${size}`, filters);
  return onFetchStagJarvis({url});
};

export const onFetchUserBankDetails = driverId =>
  onFetchStagJarvis({
    url: `/driver/bank-account?driver_id=${driverId}`,
    method: 'GET',
  });

// referral campaign APIs

export const fetchReferralCampaignList = (page, size, filters) => {
  const url = modifyUrlForFiltering(`/referral/campaign?page=${page}&page_size=${size}`, filters);
  return onFetchStagJarvis({url});
};

export const fetchBusinessVerticalList = () => {
  return onFetchStagJarvis({url: `/dropdown/business_type_name`});
};

export const onPostReferralCampaign = payload => {
  return onPost1StagJarvis({
    url: '/referral/campaign',
    method: 'POST',
    data: payload,
  });
};

export const onPatchCampaignStatus = (id, payload) => {
  return onPatchStagJarvis({
    url: `/referral/campaign/${id}`,
    method: 'PATCH',
    data: payload,
  });
};

export const fetchMilestonesList = () => {
  return onFetchStagJarvis({
    url: '/common_utils/lov?lov_names=referral_milestones',
  });
};

export const fetchCampaignDetails = id => {
  return onFetchStagJarvis({
    url: `/referral/campaign/${id}`,
  });
};

export const onPutCampaignDetails = (id, payload) => {
  return onPatchStagJarvis({
    url: `/referral/campaign/${id}`,
    method: 'PUT',
    data: payload,
  });
};

export const onPostCampaignMilestone = payload => {
  return onPost1StagJarvis({
    url: '/referral/milestone',
    method: 'POST',
    data: payload,
  });
};

export const onPutCampaignMilestone = (id, payload) => {
  return onPatchStagJarvis({
    url: `/referral/milestone/${id}`,
    method: 'PUT',
    data: payload,
  });
};

export const onDeleteCampaignMilestone = id => {
  return onDeleteJarvis({
    url: `/referral/milestone/${id}`,
  });
};

export const fetchReferralsList = (page, size, filters, city, type) => {
  const url = modifyUrlForFiltering(
    `/referral/?city_id=${city}&page=${page}&page_size=${size}&view_type=${type}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const onfetchOnboardCallHistory = (page, size, filters, city, startDate, endDate) => {
  const url = modifyUrlForFiltering(
    `/driver/onboard-call-history?city_id=${city}&start_date=${startDate}&end_date=${endDate}&page=${page}&page_size=${size}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const fetchReferrerDetails = (page, size, filters, city, type) => {
  const url = modifyUrlForFiltering(
    `/referral/user_type?type=referrer&city_id=${city}&page=${page}&page_size=${size}&view_type=${type}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const fetchRefereeDetails = (page, size, filters, city, type) => {
  const url = modifyUrlForFiltering(
    `/referral/user_type?type=referee&city_id=${city}&page=${page}&page_size=${size}&view_type=${type}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const fetchReferralReport = (page, size, filters, city, startDate, endDate, type) => {
  const url = modifyUrlForFiltering(
    `/referral/reports?city_id=${city}&page=${page}&page_size=${size}&start_date=${startDate}&end_date=${endDate}&view_type=${type}`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const fetchReferralDetails = (filters, referral_id) => {
  const url = modifyUrlForFiltering(`/referral/call-history/${referral_id}`, filters);
  return onFetchStagJarvis({url});
};

export const fetchActiveCampaignDetail = city => {
  return onFetchStagJarvis({url: `/referral/campaign?city_id=${city}&is_active=true`});
};

export const onPostCallHistory = (id, payload) => {
  return onPost1StagJarvis({
    url: `/referral/call-history/${id}`,
    method: 'POST',
    data: payload,
  });
};

export const onPostNewCallHistory = payload => {
  return onPost1StagJarvis({
    url: `/common_utils/new-call-history`,
    method: 'POST',
    data: payload,
  });
};

export const onFetchNewCallHistory = (entityId, filters) => {
  const url = modifyUrlForFiltering(
    `/common_utils/new-call-history?category=onboard-call-history-category&sub_category=onboard-call-history-subcategory&entity_id=${entityId}&sync_call_data=true`,
    filters
  );
  return onFetchStagJarvis({url});
};

export const onCreateNewReferral = payload => {
  return onPost1StagJarvis({
    url: `/referral/add`,
    method: 'POST',
    data: payload,
  });
};

export const onFetchBannerImages = city => {
  return onFetchStagJarvis({url: `/fleet_apis/advertisement/${city}?banner_type=2`});
};

export const getLockInPeriod = driver_id => {
  return onFetchStagJarvis({url: `/driver/contract-details?driver_id=${driver_id}`});
};

export const getUnSignedContract = driver_id => {
  return onFetchStagJarvis({url: `/driver/contract_view?driver_id=${driver_id}`});
};
